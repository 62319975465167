.mainContainer {
  display: flex;
  align-items: center;
  /* margin-right: 15px; */
  /* margin-bottom: 15px; */
  /* width: 90px; */
}

.mainContainer span {
  font-size: 12px;
  margin-inline-start: 5px;
  font-weight: 400;
  flex-shrink: 0;
}
.mainContainer a{
  color:unset !important;
}