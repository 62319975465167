.box {
  user-select: none;
  height: 200px;
  background-color: var(--white-clr);
  border-radius: 5px;
}
.video_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #000;
  border-radius: 10px;
}
.box label {
  font-size: 18px;
  margin-top: 20px;
  color: var(--label-color);
}

.uploadImageBox {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0px 0 5px 2px #0000000d;
  position: relative;
}

.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.uploadBox .icon {
  height: 75px;
  width: 75px;
  color: var(--primary-clr);
}

.uploadBox .uploadIcon {
  position: absolute;
  right: 12px;
  top: 10px;
  background-color: var(--primary-clr);
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px ;
}


.uploadBox .uploadIcon svg {
  color: var(--white-clr);
  font-size: 20px;
}
.uploadIcon:disabled{
  cursor: not-allowed;
}

/* Image Uploaded */
.imageUploaded {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: relative;
}

.imageUploaded img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: inherit;
}

.imageUploaded .editAndDelete {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.imageUploaded .editAndDelete .icon {
  background-color: var(--primary-clr);
  padding: 8px;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #afa8a8;
}

.imageUploaded .editAndDelete .icon svg {
  color: var(--white-clr);
  vertical-align: middle;
  width: 16px;
  height: 16px;
  display: inherit;
}

.closeIcon {
  position: absolute;
  top: 10px;
  left: 12px;
  background-color: var(--primary-clr);
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #afa8a8;
  z-index: 2;
}

.closeIcon svg {
  color: var(--white-clr);
  vertical-align: middle;
  display: flex;
}

.label {
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .box {
    height: 180px;
  }
}

@media screen and (max-width: 500px) {
  .box {
    height: 160px;
  }
}

@media screen and (max-width: 425px) {
  .box {
    height: 140px;
  }
}
