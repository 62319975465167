/* .container {
  width: 100%;
  height: 100%;
} */

.controls {
  width: 20%;
  padding: 1rem;
  background: #14161a;
  color: #fff;
}

.mt_5 {
  margin-top: 20px;
}
.label {
  color: var(--input-label-color);
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;

}
.controls input {
  border: none;
}

.map-container {
  width: 100%;
  height: 100%;
}

.combobox-input {
  width: 100%;
  /* padding: 0.5rem; */
}

.combobox-input {
  font-size: 16px;
  color: var(--text-black-clr);
  border-radius: inherit;
  background-color: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: var(--white-clr);
  width: 100%;
  border-radius: 5px;
  padding: 12.5px 16px;
  width: 100%;
  border: 1px solid var(--input-border-clr)
}

.comboOption {
  font-family: PlusJakartaDisplay-Regular;
  font-size: 15px;
}

.comboRoot {
  width: 100%;
  background-color: var(--white-clr);
  box-shadow: 0px 4px 11px rgb(34 34 34 / 21%);
  border-radius: 10px;
  padding: 20px 30px !important;
  margin-top: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, 10px);
  left: 50%;
  right: 50%;
}

.combobox-input::placeholder {
  color: var(--placeholder-clr);
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;

}

.combobox-input:active {
  border: none;
  outline: none;
}

.deleteIcon {
  position: absolute;
  top: 38%;
  right: 2%;
  color: #c5c5c5;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* blackInput */
.blackInput {
  border-radius: 10px !important;
  background: transparent !important;
  color: #fff;
  font-family: "display-Regular" !important;
}

.blackInput::placeholder {
  color: gray !important;
  font-family: "display-Regular" !important;
}

.blackList {
  color: #000 !important;
  background: #cecece !important;
}


.placesInput[data-variant=dashboard] label{
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
  
}
.placesInput[data-variant='dashboard'] .comboRoot{
  background-color: var(--dashboard-input-bg-clr);  
}
.placesInput[data-variant='dashboard'] .combobox-input{
  background-color: var(--dashboard-input-bg-clr);
  border-radius: 6px;
  border: none;
  padding: 15.5px 16px;

  
}
