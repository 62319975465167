.noDataContainer {
  min-height: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noDataContainer svg {
  color: var(--primary-clr);
}

.noDataContainer p {
  color: var(--primary-clr);
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.adminThemeColor p,
.adminThemeColor svg {
  color: var(--primary-clr);
}
