
.row{
    row-gap: 10px;
}
.modal{
    max-height: 75vh;
    overflow: auto;
    padding-bottom:30px;
    padding-top:50px;

}



/* New Css */
/* Amenities */
.listWrap {
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.listSelection{
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.listSelection li{
    padding: 6px 16px;
    border-radius: 40px;
    border: 1px solid var(--primary-clr);
    cursor: pointer;
    color: var(--text-black-clr);
    font-weight: 500;
    user-select: none;

}
.listSelection li[data-selected =true]{
    background-color: var(--primary-clr);
    color: var(--white-clr);

}
.btn{
    margin-top: 20px;
    width: 100%;
}