.header {
  color: var(--primary-clr);
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 25px;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input {
  border-radius: 20px;
}
.forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgot p {
  margin: 0px !important;
  color: #0591e8;
}
.btn {
  margin-top: 15px;
  width: 100%;
  border-radius: 5px;
}
.footer {
  margin-top: 10px;
}
.footer p {
  margin: 0px !important;
}
.footer p:first-child {
  text-align: center;
}
.footer p:last-child {
  text-align: center;
  color: #ee8a1d;
  cursor: pointer;
}
