.btn {
  outline: none;
  line-height: 1;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s all;
  background-color: transparent;
  color: var(--white-clr);
  padding: 15px 30px 
}
.btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.btn[data-variant='primary'] {
  background: var(--primary-clr);
  color: var(--white-clr);
}
.btn[data-variant='secondary'] {
  background: var(--secondary-clr);
  color: var(--white-clr);
}
.btn[data-icon='true'] {
  display: flex;
  align-items: center;
}

.btn[data-variant='primary']:hover {
  background: var(--primary-clr-hover);
}
.btn[data-variant='secondary']:hover {
  background: var(--secondary-clr-hover);
}

.btn[data-left-icon='true'] svg {
  float: left;
}
.btn[data-right-icon='true'] svg {
  float: right;
}

.btn[data-right-icon='true'] span {
  margin-inline-end: 8px;
}
.btn[data-left-icon='true'] span {
  margin-inline-start: 8px;
}
