.mainContainer {
  display: block;
  place-items: center;
  background: white;
  box-shadow: 0px 0px 14px #00a99733;
}
.detailContainer {
  padding-inline: 10px;
}
.detailContainer h6 {
  font-size: 18px;
  margin-block: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.detailContainer p {
  font-size: 14px;
  margin: 0px !important;
  margin-bottom: 10px !important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.imageContainer {
  width: 100%;
  height: 180px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.bottomContainer {
  border-top: 2px solid #cccccc;
  padding-block: 10px;
  margin-inline: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bottomContainer p {
  margin: 0px !important;
  font-size: 14px;
}
.bottomContainer svg {
  margin-right: 5px;
}
.bottomContainer p:first-of-type {
  margin-right: 15px !important;
}
