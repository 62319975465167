.inputWithSearch {
  width: 100%;
  height: 48px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border: 1px solid #b8b8b8;
  border-right: 0px;
}
.inputWithSearch[data-variant='dashboard'] {
  border: none;
  position: relative;
}
.inputWithSearch[data-variant='dashboard'] input {
  padding-left: 58px;
}
.inputWithSearch[data-variant='dashboard'] input::placeholder {
  color: var(--text-black-clr);
}
.inputContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.inputWithSearch .icon {
  background: var(--secondary-clr);
  color: white;
  /* width: 48px;
    height: 48px; */
  padding: 10px;
}

.inputWithSearch[data-variant='dashboard'] .icon {
  color: var(--text-black-clr);
  background-color: inherit;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
