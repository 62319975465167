.page {
    background-color: var(--page-bg-color);
  }
  
  .containerWrapper {
    min-height: calc(100vh - 245px);
    max-height: auto;
  }
  
  .buttonContainer {
    display: grid;
    place-items: center;
    margin-top: 10px;
  }
  
  .buttonContainer button {
    width: 200px;
  }

  .innerRow{
    row-gap: 20px;
  }
  .infoLine {
    font-weight: 600;
    margin-bottom: 0px;
  }
  .btnCenter{
    display: flex;
    justify-content: center;
  }
  .row{
    row-gap: 30px;
  }
  .decriptionBox{
    background-color: var(--white-clr);
    padding: 20px;
    height: calc(100vh - 362px);
    overflow-y: scroll;
  }
  .decriptionBox::-webkit-scrollbar-track{
    border: 1px solid #d2d2d2;
  }
  .decriptionBox::-webkit-scrollbar-thumb{
    background: #eff2f9;
    border: 1px solid #d2d2d2;

  }
  .decriptionBox::-webkit-scrollbar{
    background: #f6f6f6;
  }

  .tab{
    background-color: transparent !important;
    border-bottom: 1px solid var(--primary-clr) !important;
    box-shadow:unset !important;
  }
  .tabLi{
    background-color: var(--white-clr);
    min-width: 265px;
    text-align: center;
  }
  .tabLi span{
    width: 100%;
  }