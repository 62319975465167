.cardContainer {
  padding: 20px 0;
  background-color: #fff;
  cursor: pointer;
  box-shadow: var(--card-shadow);
  border-radius: 8px;
  overflow: hidden;
}

/* top */
.topStrip {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.imageContainer {
  width: 35%;
  margin-inline-end: 5px;
}
.imageContainer img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.topStripInfo {
}
.topStripInfo p:nth-child(1) {
  color: rgb(130 125 125);
  font-size: 18px;
  margin-bottom: 0 !important;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.topStripInfo p:nth-child(2) {
  color: rgb(130 125 125);
  font-size: 12px;
  margin-bottom: 0 !important;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: -3px;
}

/* image */
.agentImageContainer {
  width: 70%;
  height: 200px;
  margin-inline: auto;
  margin-bottom: 10px;
}
.agentImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* nameAndDesignation */
.nameAndDesignation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
}

.nameAndDesignation p:nth-child(1) {
  color: var(--primary-clr);
  font-size: 22px;
  margin-bottom: 0 !important;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
}
.nameAndDesignation p:nth-child(2) {
  color: rgb(130 125 125);
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 0 !important;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: -3px;
}

/* propertiesInfo */
.propertiesInfo {
  margin: 10px 20px;
  padding: 5px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.paraHeading {
  color: #000;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.propertyCountInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.propertyCountInfo p {
  color: #484747;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.propertyCountInfo p span {
  color: var(--secondary-clr);
  margin-inline-end: 5px;
}

.propertyCountInfo p:nth-child(1) {
  position: relative;
  width: 45%;
}
.propertyCountInfo p:nth-child(1)::before {
  content: '';
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  width: 1px;
  height: 90%;
  background-color: #eee;
}

/* languagesInfo */
.languagesInfo {
  padding: 0 20px;
}

.languagesInfo p:nth-child(2) {
  color: #484747;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

/* @media (max-width:600px) {
  .paraHeading {
    font-size: 16px;
   
  }
} */
