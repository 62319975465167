.mainPage {
  background-color: var(--page-bg-color);
}

.mainContainer {
}

.tabsWrapper {
  border-bottom: 1px solid var(--primary-clr);
  margin-top: 40px;
  margin-bottom: 30px;
}

/* .tabsContainer {
  width: max-content;
  margin-bottom: 0;
} */

.tabsContainer li {
  width: 220px;
}
.tabsContainer li span {
  margin: 0 auto;
}

.dataCountStripContainer {
  background: white;
  padding: 10px 20px;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.filters {
  margin-inline-end: 20px;
}
.dataStripRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataStripRight span {
  margin-inline-end: 15px;
  color: gray;
}

.propertiesContainer {
  margin: 30px 0;
  display: flex;
  row-gap: 20px;
}

.dataAndBannerContainer {
  display: flex;
}

.bannerAdContainer {
  width: 100%;
  height: 500px;
  position: sticky;
  top: 130px;
}

.verticleBanner {
  height: 600px;
}
.verticleBanner img {
  object-position: top center;
}
.horizontalBanner {
  height: 250px;
}
.itemLoader {
  height: 400px;
}

@media (max-width: 760px) {
  .tabsContainer {
    width: 100%;
  }

  .tabsContainer li {
    width: 33.33%;
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .bannerAdContainer {
    display: none;
  }
}
