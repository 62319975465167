.statWrap {

    padding: 20px;
    border-radius: 10px;
    min-height: 160px;
    background-color: #FFFFFF;
    /* box-shadow: 13px 13px 28px #654ACB26;
    background-color: var(--white-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    justify-content: center;
    height: 150px;
    width: 100%;
    padding-left: 20px;
    position: relative;
    transition: background 0.5s ease-in-out;
    cursor: pointer; */
}
.contentWrap{
    display: flex;
    justify-content: space-between;
}
.content{
    color: var(--575-clr);
}


.current{
    font-size: 45px;
    font-weight: bold;
    margin-right: 5px;
    line-height: 1;
}
.total{
    font-size: 18px;
    line-height: 1;

}
.bars{
    display: flex;
    gap: 60px;
}
.barWrap{
    display: flex;
    align-items: center;
    gap: 5px;
    flex-grow: 1;
}
.barWrap span{
    font-size: 14px;
}
.bar{
    width: 100%;
    background-color: #E0E3E6;
    height: 10px;

}

.innerBar{
    height: 100%;
}

.icon svg{
    height: 100px;
    width: 70px;
}
/* .statWrap p {
    font-size: 17px;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 0px !important;
}

.odd_h3 {
    color: #E65D90;
    margin-bottom: 0px;
}

.even_h3 {
    color: #654ACB;
    margin-bottom: 0px;
}

.cardImg {
    position: absolute;
    right: 0px;
    top: 0px;
}

/* Hover */
/* .statWrap:hover {
    background: #fbcbddbf;
}

@media screen and (max-width:1600px) {
    .statWrap p {
        font-size: 16px;
    }

    .statWrap h4 {
        font-size: 30px;
    }
}  */