.page {
  background-color: var(--page-bg-color);
}
.heroSection{
  height: 35rem !important;

}

.heroContainer {
  /* display: flex; */
  /* justify-content: center; */
  padding-block: 120px;
  height: 100%;
}
.filtersContainer{
  max-width: 85%;
}
.dataStripRight {
  display: flex;
  justify-content: end;
  align-items: center;
}

.pagePadding {
  padding: 30px 0;
}

.dataStripRight span {
  margin-right: 15px;
  color: gray;
}

.pagination{
  margin-bottom: 20px;

}
.innerRow{
  row-gap: 15px;
  margin-top: 30px;
}


@media screen and (max-width:1220px) {

}
@media screen and (max-width:1140px) {

}
@media screen and (max-width:992px) {
  .filtersContainer{
    max-width: 100%;
  }
 
}