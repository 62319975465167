.checkboxWithLabel {
  display: flex;
  align-items: center;
  height: 100%;
}

.checkboxWithLabel label {
  line-height: 1;
  margin-inline-start: 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.container {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid var(--input-border-clr);
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #5a9cff;
  border: 0px solid transparent;
}

.container input:disabled~.checkmark {
  background-color: transparent;
  border: 2px solid #dddddd80;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  right: 6px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.labelDisabled {
  color: #dddddd;
}

.labelChecked {}

.label {
  display: flex;
  flex-direction: column;
}