.main{
  min-height: calc(100vh - 240px);
}

.page {
  background-color: var(--page-bg-color);
}
.heroContainer {
  max-width: 75%;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  height: 100%;
}
.sectionHeading {
  text-transform: capitalize;
  margin-bottom: 30px;
}
.sectionHeading span {
  font-weight: 500;
}

.mt60 {
  margin-top: 60px;
}
.mt30 {
  margin-top: 30px;
}
.pt30 {
  padding-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.banner {
  margin-block: 30px;
}
.banner img {
  height: 100%;
  width: 100%;
}
.tabsWrapper {
  border-bottom: 1px solid var(--primary-clr);
  margin-top: 40px;
  margin-bottom: 30px;
}
.tabsContainer {
  width: max-content;
  margin-bottom: 0;
}

.tabsContainer li {
  width: 220px;
}
.tabsContainer li span {
  margin: 0 auto;
}

.viewAllBtn {
  width: max-content !important;
  padding: 12px 60px !important;
}
.hrCenter {
  display: flex;
  justify-content: center;
}
.row {
}
.paginationDiv{
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .heroContainer {
    max-width: 92%;
  }
}
@media screen and (max-width: 768px) {
  .row {
    row-gap: 20px;
  }
}
