


.filters{

}


.dropdownBox {
    height: 51px;
    background-color: var(--white-clr);
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    column-gap: 10px;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;
    /* box-shadow: 0.26px 0.97px 3px #00010117; */
    border: 1px solid var(--input-border-clr);
}

.dropdownBox span {
    color: var(--text-black-clr);
    font-weight: 500;
    text-transform: capitalize;
}

.dropdownBox .icon {
    color: var(--secondary-clr);
}
.dropdownBox > div{
    display: flex;
    align-items: center;
    gap: 10px;
}

.overlayBox {
    background-color: var(--white-clr);
    padding: 16px;
    box-shadow: 6px 5px 5px 2px #0000002e;
    width: 100%;
    z-index: 10;
    min-width: 250px;
}
.minWidth350{
  min-width: 350px;
}
.mt20{
  margin-top: 20px;
}
.p0{
    padding: 0px;
}
.flex2{
  flex: 2 1;
}

.listOptionsWithBrBtm {
    padding-left: 0px;
    margin-bottom: 0px;

}

.listOptionsWithBrBtm li {
    border-bottom: 1px solid var(--text-light-gray-clr);
    padding: 5px 10px;
    font-weight: 500;
    color: var(--text-black-clr);
    text-transform: capitalize;
  cursor: pointer;

}

.listOptionsWithBrBtm li[data-selected=true] {
    background-color: var(--primary-clr);
    color: var(--white-clr);
}
.row {
    column-gap: 8px;
}
.row > div{
    flex: 1 1;
}

.minWidth170 {
  min-width: 170px !important;
  flex-grow: 0 !important;
}
.minWidth190 {
  min-width: 190px !important;
  flex-grow: 0 !important;
}
.innerRow{
    row-gap: 10px;
}
.headWithIcon {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headWithIcon svg {
    color: var(--secondary-clr);
    font-size: 20px;
}

.headWithIcon h6 {
    margin-bottom: 0px;
}
.spBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.btnMaxContent {
  width: calc(50% - 10px) !important;
  padding: 14px 5px !important;
  border: 1px solid var(--primary-clr) !important;
  font-size: 14px;
}
.clearBtn, .clearBtn:hover{
  width: calc(50% - 10px);
  padding: 14px 5px !important;
  background-color: var(--white-clr) !important;
  color: var(--primary-clr) !important;
  border: 1px solid var(--primary-clr) !important;
  font-size: 14px;
}


.searchBtn {
    height: 51px !important;
    width: max-content !important;
}

@media screen and (max-width: 992px) {
    .minWidth170 {
      min-width: 150px !important;
    }
  }
  
@media screen and (max-width: 768px) {
    .minWidth170 {
      min-width: 120px !important;
    }
  }
  

  @media screen and (max-width: 600px) {
    .minWidth170 {
      min-width: 110px !important;
    }
    .searchBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px !important;
      width: 54px !important;
      font-size: 20px;
    }
    .searchBtn span svg {
      float: unset;
    }
    .minWidth170 {
      min-width: 54px !important;
    }
    .minWidth170 .dropdownBox {
      justify-content: center;
    }
    .minWidth170 .dropdownBox svg {
      font-size: 20px;
    }
  }

  
  @media screen and (max-width: 500px) {
    .searchBtn {
      width: 48px !important;
    }
    .minWidth170 {
      min-width: 48px !important;
    }
    .minWidth350{
      min-width: 100%;
    }
  }

  
  @media screen and (max-width: 375px) {
    .minWidth170 {
      min-width: 45px !important;
    }
    .dropdownBox span {
      font-size: 18px;
    }
    .searchBtn {
      font-size: 18px;
      width: 45px !important;
    }
  }
  