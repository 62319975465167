/*//////////////////////////////////////////////////////////////////
[ FONT ]*/
div#checkdv_1 {
  display: none;
}

:root {
  /* New Colors */
  --primary-clr: #00a997;
  --primary-clr-hover: #04a291;
  --secondary-clr: #f28e1d;
  --secondary-clr-hover: #f6992f;
  --white-clr: #fff;
  --text-black-clr: #000;
  --icon-count-clr: #ff0000;
  --text-dark-gray-clr: #656565;
  --text-light-gray-clr: #a8a8a8;
  --input-label-color: #000;
  --placeholder-clr: #8b8b8b;
  --input-border-clr: #ccc;
  --disabled-input-clr: #fafafa;
  --btn-primary-color: #ee8a1d;
  --btn-secondary-color: #ea2344;
  --btn-color-grey: #868686;

  --arrow-color: #e8871c;
  --border-color: #adacad;
  --border-light-color: #e6eaeb;

  --page-bg-color: #ebebeb;

  /* New Colors */

  --sidebar-text-clr: #6a707e;
  --footer-text-clr: #d1d1d1;
  --disabled-label-clr: #ababab;
  --error-clr: red;
  --list-item-border-clr: #a8a8a838;
  --ccc-clr: #ccc;
  --d8d-clr: #ccc;
  --dashboard-page-bg-clr: #f3f6f9;
  --575-clr: #575757;
  --dashboard-text-gray-clr: #847c7c;
  --dashboard-input-label-clr: #029a8a;
  --dashboard-input-bg-clr: #e0f3f1;
  --action-icon-clr: #00a997;
  --card-shadow: 0 3px 3px -3px rgba(0,0,0,.3);
  --7070-clr:#707070;

  /* New Colors */
}
.aEnd{
  display: flex;
  align-items: end;
}

.c-p {
  cursor: pointer;
}
.c-d{
  cursor:no-drop;
}
.rtl{
  direction: rtl;
  position: relative;
}
.ltr{
  direction: ltr;
  position: relative;

}
.t-t-c{
  text-transform: capitalize;
}
body {
}
.DropdownOptionContainer__menu{
  z-index: 10 !important;
}

.scroller::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
  border-radius: 4px;

}

.scroller::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
  height: 6px;
}

.scroller::-webkit-scrollbar-thumb
{
	background-color: var(--primary-clr);
  border-radius: 4px;

}

.disabledMenuItem{
  color: var(--dashboard-text-gray-clr) !important;
  cursor: not-allowed !important;
  opacity: 0.8;

}
.disabledMenuItem:hover{
  background-color: transparent !important;
}


/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  word-spacing: 2px;
  font-family: 'Montserrat', sans-serif;
}
.rec-arrow-left, .rec-arrow-right{
  direction: ltr;
}
/*---------------------------------------------*/
.label {
  color: var(--text-black-clr);
  text-transform: capitalize;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
}

a.link {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-clr);
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a.link:focus {
  outline: none !important;
}
.page_bg {
  background-color: var(--page-bg-color);
}
a.link:hover {
  text-decoration: none;
}
.dropDownMain__input-container {
  margin: 0;
}
.dropDownMain__value-container {
  padding-top: 0px;
  padding-bottom: 0px;
}
.dropDownMain__indicator {
  padding: 0px !important;
}
.p14 {
  font-size: 14px;
  font-weight: 400;
}

.p16 {
  font-size: 16px;
  font-weight: 400;
}

.p18 {
  font-size: 18px;
  font-weight: 400;
}

.primary-color {
  color: var(--primary-clr);
}

.secondary-color {
  color: var(--secondary-clr);
}

body h1 {
  font-size: 57px;
  font-weight: 600;
}

body h2:not(.med) {
  font-size: 42px;
  font-weight: 600;
}

body h2.med {
  font-size: 42px;
  font-weight: 500;
}

body h3 {
  font-size: 38px;
  font-weight: 600;
}

body h4 {
  font-size: 30px;
  font-weight: 600;
}

body h5 {
  font-size: 24px;
  font-weight: 600;
}

body h6 {
  font-size: 20px;
  font-weight: 600;
}
.__react_component_tooltip {
  user-select: none;
}
/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
svg {
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
  border: none;
}

.footer_text {
  font-size: 15px !important;
  font-weight: 400;
}

.min_h6 {
  font-size: 20px !important;
  font-weight: 400;
}

.hero_text {
  font-size: 29px !important;
  font-weight: 600;
}

.SkeletonContainer {
  display: inline-block;
  height: 350px;
  width: 95%;
  animation: skeleton-loading 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: '0';
}

@keyframes skeleton-loading {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

/* Dashboard Font Family */
.dashboardWrapper * {
  font-family: 'Poppins', sans-serif !important;
}

.dashboardWrapper h1,
.dashboardWrapper h2,
.dashboardWrapper h3,
.dashboardWrapper h4,
.dashboardWrapper h5,
.dashboardWrapper h6 {
  font-weight: 700;
}

/* Dashboard Font Family */
.customContainer2 {
  max-width: 90%;
  margin: 0 auto;
}
.customContainer {
  max-width: 85%;
  margin: 0 auto;
}

/* Responsive Queries */

@media (max-width: 1440px) {
  .customContainer {
    max-width: 90%;
    margin: 0 auto;
  }
  .customContainer2 {
    max-width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .customContainer {
    max-width: 92%;
    margin: 0 auto;
  }

  body h1 {
    font-size: 50px;
  }

  body h2:not(.med) {
    font-size: 40px;
  }

  body h2.med {
    font-size: 40px;
  }

  body h3 {
    font-size: 35px;
  }

  body h4 {
    font-size: 28px;
  }

  body h5 {
    font-size: 23px;
  }

  body h6 {
    font-size: 19px;
  }
  span,
  p {
    font-size: 15px;
  }
}
@media screen and (max-width: 425px) {
  .customContainer {
    max-width: 100%;
  }
  .customContainer2 {
    max-width: 100%;
  }
  span,
  p {
    font-size: 14px;
  }
}
/* .rec-slider-container {
  padding-block: 2rem;
} */

.rec-item-wrapper {
  overflow: visible !important;
}

.rec-arrow {
  background-color: var(--secondary-color) !important;
  border-radius: 2px !important;
  color: var(--white-clr) !important;
}
.j-center {
  display: flex;
  justify-content: center;
}

.rec-arrow-right {
  margin-inline-end: 1rem;
}

.react-time-picker__wrapper {
  border: unset;
}
.add_propery_date_range_time {
  display: flex;
  position: relative;
  background-color: var(--white-clr);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 100%;
  padding: 11px 16px;
  width: 100%;
  border: 1px solid var(--input-border-clr);
}
.add_propery_date_range {
  display: flex;
  position: relative;
  background-color: var(--white-clr);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 100%;
  padding: 15px 16px;
  width: 100%;
  border: 1px solid var(--input-border-clr);
}
.rec-arrow-left {
  margin-inline-start: 1rem;
}
.react-slideshow-container .nav{
  z-index: 9 !important;
}

.react-slideshow-wrapper .images-wrap {
  height: 100% !important;
}
.flex-align-center {
  display: flex;
  align-items: center;
  gap: 5px;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.jCenter {
  display: flex;
  justify-content: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bannerHorizontal {
  /* height: 175px !important; */
}
.txt-primary {
  color: var(--primary-clr);
}
.textOneLine{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (min-width: 1025px) {
  .verticalBannerWidth {
    width: 250px !important;
  }
  .verticalBannerAndContentWidth {
    width: calc(100% - 250px) !important;
  }
}
