.header {
  color: var(--text-black-clr);
  display: flex;
  font-weight: bold;
  font-size: 25px;
  padding-inline: 32px;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input {
  border-radius: 20px;
}
.forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgot p {
  margin: 0px !important;
  color: #0591e8;
}

.btn {
  margin-top: 10px;
  width: 100%;
  padding-block: 15px !important;
  border-radius: 5px !important;
}
.footer {
  margin-top: 10px;
}
.footer p {
  margin: 0px !important;
}
.footer p:first-child {
  text-align: center;
}
.footer p:last-child {
  text-align: center;
  color: #ee8a1d;
  cursor: pointer;
}
.body {
  background-color: var(--page-bg-color);
}
.gap {
  row-gap: 10px;
}
.iconsRow {
  row-gap: 10px;
  margin-top: 10px;
}
.contactWrap p {
  margin: 0px !important;
  font-weight: 600;
}
.methodWrap {
  display: flex;
  gap: 10px;
}

.propertyCard {
  background-color: var(--white-clr);
  border: 1px solid var(--input-border-clr);
  border-radius: 5px;
  overflow: hidden;
}
.propertyCard .imgDiv {
  width: 100%;
  height: 150px;
  border-inline-end: 1px solid var(--input-border-clr);
}
.propertyCard .imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.propertyCard .detailInfo {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.propertyCard .detailInfo p {
  text-transform: capitalize;
}
.propertyCard .detailInfo h5 {
  font-size: 18px;
  margin-bottom: 2px;
  font-weight: 700;
  text-transform: capitalize;
}
.propertyCard .detailInfo h6 {
  font-size: 20px;
  margin-bottom: 2px;
  font-weight: 500;
  text-transform: capitalize;
}
.price {
  color: var(--primary-clr);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}
.price span {
  color: var(--text-black-clr);
  font-size: 16px;
  font-weight: 500;
}

.addressWrap {
  display: flex;
  align-items: center;
  gap: 5px;
}
.addressWrap p {
  margin: 0px !important;
  color: #656565;
  font-size: 11px;
}

.propertyDetailWrap {
  margin-bottom: 10px;
}
.propertyDetail {
  display: flex;
  align-items: center;
  gap: 8px;
}
.propertyDetail p {
  margin: 0px !important;
  font-size: 12px;
  color: #656565;
  font-weight: 500;
}
.tagline {
  color: #494949;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.location {
  display: flex;
  align-items: start baseline;
}
.location svg {
  flex-shrink: 0;
}

.location span {
  font-size: 12.5px;
  margin-left: 5px;
}
