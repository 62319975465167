.profileEditContainer {
  /* width: 200px;
  height: 200px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid #929eaa;
  background-color: #d6d6d6;
  cursor: pointer;
  width: 130px;
  height: 130px;
}

.profileEditImage_box {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profileEditImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.profileEditPen_box {
  width: 40px;
  height: 40px;
  background: #fff;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8%;
  bottom: 5%;
  opacity: 1;
  border: 1px solid var(--placeholder-color);
}

.profileEditPen {
  font-size: 18px;
  cursor: pointer;
}

.file_upload_form3rd[type="file"] {
  display: none;
}

@media (max-width: 700px) {
  .profileEditPen_box {
    width: 28px;
    height: 28px;
   
  }
}

@media screen and (max-width:1220px) {
  .profileEditContainer {
      width: 180px;
      height: 180px;
     
    } 
}
@media screen and (max-width:1024px) {
  .profileEditContainer {
      width: 170px;
      height: 170px;
     
    } 
}
@media screen and (max-width:768px) {
  .profileEditContainer {
      width: 150px;
      height: 150px;
     
    } 
    .profileEditPen_box {
      width: 32px;
      height: 32px;
     
    }
}
