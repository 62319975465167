.textAreaBox {
  position: relative;
}

.textAreaBox label {
  color: #000;
  text-transform: capitalize;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
}

.textAreaBox textarea {
  border-radius: 5px;
  border: none;
  padding: 10px 8px;
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  background-color: var(--white-clr);
  border: 1px solid var(--input-border-clr);
  font-weight: 400;
  font-size: 16px;
}

.textAreaBox textarea::placeholder {
  color: var(--placeholder-clr);
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}
.textAreaBox textarea:disabled {
  background-color: var(--disabled-input-clr);
}

.labelDisabled{
  color: var(--disabled-label-clr);
}

.textAreaBox[data-variant=dashboard] label{
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;

}
.textAreaBox[data-variant=dashboard] textarea{
  background-color: var(--dashboard-input-bg-clr);
  border-radius: 6px;
  border: none;

}
.textAreaBox[data-variant=dashboard] textarea::placeholder{

}