
.label{
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.mainConatiner {
  padding: 7px 10px;
  background-color: #ffff;
  border: 1px solid #CED6D6;
border-radius: 6px;
opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 10px;
  gap: 10px;
  position: relative;
}
.mainConatiner p{
margin: 0px !important;
text-overflow: ellipsis;
overflow: hidden;
}


.crossIcon{
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
}

.mainConatiner button{
  flex-shrink: 0;
}