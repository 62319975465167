.phoneMain{
  direction: ltr;
}
.phoneMain[data-variant='dashboard']{

}

.phoneInput {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px !important;
  color: var(--text-color-black);
  letter-spacing: 1.4px !important;
  background-color: var(--white-clr) !important;
  width: 100% !important;
  min-height: 54px !important;
  border-radius: 5px !important;
  border: 1px solid var(--input-border-clr);
}

.phoneInputContainer {
  border: none !important;
}

.phoneInput:focus {
  box-shadow: none !important;
}

.inputPassContainer input::placeholder {
  color: var(--placeholder-clr);
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-weight: 400;

}
.phoneLabel {
  color: var(--input-label-color);
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;
}
.phoneMain[data-variant='dashboard'] .phoneInput{
  min-height: 55px !important;
  background-color:var(--dashboard-input-bg-clr) !important;
  border: none;

}

.phoneMain[data-variant='dashboard'] .phoneLabel{
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
}