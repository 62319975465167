.modal {
}

/* 
  .modalContent {
    width: 100%;
    height: 100%;
  } */

/* .modal-body {
    padding: 0px;
  } */

.header {
  padding: 0px;
  background-color: var(--primary-clr);
  height: 65px;
  padding-inline: 20px;
  color: var(--white-clr);
}

.header button {
  padding: 0px !important;
  margin: 0px !important;
  /* position: absolute; */
  right: 27px;
  top: 27px;
  opacity: 1;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat !important;
  color: var(--white-clr);
}
.header h6 {
  color: var(--white-clr);
  margin-bottom: 0px;
  margin-inline-end: 20px;
}

.header button:hover {
  opacity: 1;
  box-shadow: unset;
}

.footer {
  padding: 16px 32px;
}

.body {
  padding: 20px;
}

.iconBox {
  position: absolute;
  inset-inline-end: 6px;
  top: 18px;
  z-index: 10;
  cursor: pointer;
}

.iconBox svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.iconBox[data-show-header='false'] svg {
  color: var(--text-black-clr);
}
.iconBox[data-show-header='true'] svg {
  color: var(--white-clr);
}
