.main {
  max-width: 95%;
  padding-block: 30px;
}

.row {
  row-gap: 20px;
}

.middle {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image {
  width: 400px;
  height: 400px;
}

@media (max-width: 767.98px) {
  .image {
    width: 250px;
    height: 250px;
  }
}
