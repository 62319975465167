.listWithCheck{
   border-bottom: 1px solid var(--list-item-border-clr) ;
   width: 100%;
   padding-bottom: 4px; 
   /* display: flex; */
}
.listWithCheck svg{
    font-size: 22px;
    color: var(--primary-clr);
}
.text{
    color: var(--text-dark-gray-clr);
    font-size: 16px;
    margin-left: 10px;
}