.row {
  row-gap: 20px;
}
.row h6{
  margin-bottom: 0px;
}
.modal {
  max-height: 75vh;
  overflow: auto;
  padding:30px 50px;
  padding-top: 50px;

}

/* New Css */
/* Amenities */
.listWrap {
  padding-left: 0px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.listWrap li {
  /* flex-basis: calc(33.33% - 10px); */
  width: auto;
}
.listWrap li > div{
  margin-block:0px !important ;
}
.checkboxDiv > div{
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
.listSelection {
  padding-left: 0px;
  margin-bottom: 0px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.listSelection li {
  padding: 6px 16px;
  border-radius: 40px;
  border: 1px solid var(--primary-clr);
  cursor: pointer;
  color: var(--text-black-clr);
  font-weight: 500;
  user-select: none;
  font-size: 16px;
}
.listSelection li[data-selected='true'] {
  background-color: var(--primary-clr);
  color: var(--white-clr);
}
.btn {
  margin-top: 20px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .listSelection li {
    font-size: 15px;
  }
}

@media screen and (max-width: 425px) {
  .listSelection li {
    font-size: 14px;
  }
}
