.footerOne{
    background-color: var(--primary-clr);
    padding: 12px;
  }
  .footerOne h6{
    color: var(--white-clr);
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  
  .footerTwo{
    background-color: var(--text-black-clr);
    padding-block:10px ;
  }
 
  .flexEnd{
    display: flex;
    justify-content: flex-end;
  }
  .agentLoginBtn{
    width: max-content !important;
    border: 1px solid var(--white-clr) !important;
  }
  .links{
    display: flex;
    align-items: center;
    column-gap: 30px;
    flex-wrap: wrap;
  }
  
  .links a{
  text-decoration: none;
  position: relative;
  
  }
  .links a:not(:last-child):after{
    content: '';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translate(-50%,-50%);
    display: block;
    width: 2px;
    height: 60%;
    background-color: var(--primary-clr);
  }
  .allRightsText{
    color: var(--white-clr);
    text-align: center;
    margin-bottom: 0px;
  }
  
  .imgDiv{
    width: 114px;
    height: auto;
    cursor: pointer;
  }
  .imgDiv img{
    height: 100%;
    width: 100%;
  }
  .textWithImages{
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: center;
  }
  
  .textWithImages > div{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
  }
  
  .linksAndCopyRightText{
    width: max-content;
    margin-left:auto;
  }
  
  
  @media screen and (max-width:1024px) {
    .links{
      column-gap: 20px;
      justify-content: center;
    }
    .links a:not(:last-child):after{
      right: -10px;
    }
    .agentLoginBtn{
      font-size: 15px;
    }
   
    
  }
  @media screen and (max-width:992px) {
    .links{
      column-gap: 20px;
      margin-block: 10px;
      
    }
    .links a:not(:last-child):after{
      right: -10px;
    }
    .footerTwo .row{
      flex-direction: column-reverse;
      column-gap: 20px;
    }
    .footerTwo .row> div{
      width: 100%;
    }
    .flexEnd{
      justify-content: center;
      width: 100%;
      
    }
 
    .footerOne h6{
      font-size: 20px;
    }
    
  }
  
  
  @media screen and (max-width:768px) {
 
    .linksAndCopyRightText{
      width: 100%;
      margin-left:0px;
    }
 
  }
  
  @media screen and (max-width:575px) {
    .textWithImages{
      flex-wrap: wrap;
      row-gap: 10px;
    }

  
  }
  @media screen and (max-width:500px) {
   
    .textWithImages h6{
      text-align: center;
      flex-grow: 1;
  
    }
  .textWithImages > div{
    width: 100%;
  }
  
  }
  
  @media screen and (max-width:375px) {
    .textWithImages h6{
      font-size: 18px;
  
    }
  }