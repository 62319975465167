.main {
  margin-top: 40px;
}

.col {
  width: 45%;
}

.row {
  justify-content: space-between;
  row-gap: 20px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 10px;
}
.head div:first-child {
  font-size: 17px;
  font-weight: 500;
}

.valueBox {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
  border: 2px solid var(--primary-clr);
}

.range {
  font-size: 13px;
  color: var(--text-dark-gray-clr);
  /* margin-top: -10px; */
}
.range span{
  font-weight: 500;
}

.col2Head {
  display: flex;
  align-items: center;
  gap: 20px;
}

.head1 {
  padding-inline: 10px;
  border-left: 5px solid var(--secondary-clr);
}
.head1 h6, .head2 h6 {
  margin: 0px !important;
  font-size: 20px;
  line-height: 1;
  /* font-weight: 600; */
}
.head1 p, .head2 p {
  margin-top: 5px !important;
  margin-bottom: 0px ;
  font-size: 17px;
  font-weight: 500;
  color: var(--text-light-gray-clr);
  line-height: 1;

}
.head2 {
  padding-inline: 10px;
  border-left: 5px solid var(--primary-clr);
}

.mainRow h6 {
  font-size: 17px;
  /* color: var(--primary-color); */
  row-gap: 10px;
  margin-bottom: 0px;
}
.mainRow {
  margin-top: 30px;
  /* row-gap: 10px; */
}
.mainRow p {
  font-size: 18px;
  color: var(--text-light-gray-clr);
  margin-bottom: 0px;
  font-weight: 500;
}
.col2Cost {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 80px;
}
.cost h4 {
  font-size: 34px;
  font-weight: 500;
  margin: 0px !important;
  color: var(--text-light-gray-clr);
}
.cost h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 0px !important;
}
.cost h4[data-color='primary'] {
  color: var(--primary-clr);
}

@media (max-width: 1024px) {
  .col {
    width: 100%;
  }
  .row {
    justify-content: center;
    row-gap: 20px;
  }
}
