.mainPage {
  background-color: var(--page-bg-color);
}

.mainContainer {
  padding-block: 2rem;
}

.tabsWrapper {
  border-bottom: 1px solid var(--primary-clr);
  margin-top: 40px;
  margin-bottom: 30px;
}

/* .tabsContainer {
  width: max-content;
  margin-bottom: 0;
} */
.itemLoader {
  height: 400px;
}

.tabsContainer li {
  width: 220px;
  /* border-right: 1px solid var(--primary-clr); */
}
.tabsContainer li span {
  margin: 0 auto;
}
.filters {
  margin-inline-end: 20px;
}
.dataCountStripContainer {
  background: white;
  padding: 10px 20px;
  box-shadow: 0px 0px 14px #00a99733;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}


.dataStripRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataStripRight span {
  margin-inline-end: 15px;
  color: gray;
}
.propertiesContainer {
  margin: 30px 0;
}

.dataAndBannerContainer {
  display: flex;
}

.bannerAdContainer {
  width: 100%;
  height: 500px;
  position: sticky;
  top: 130px;
}
.banner {
  height: 473px !important;
}
.banner img {
  height: 100%;
  width: 100%;
  object-position: top center;
}

@media (max-width: 540px) {
  .tabsContainer {
    width: 100%;
  }

  .tabsContainer li {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .bannerAdContainer {
    display: none;
  }
}
