.page {
    padding: 45px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .loader{
    min-height: calc(100vh - 80px);
  }
  
  .heading {
    color: var(--dashboard-text-gray-clr);
  }
  .mb58 {
    margin-bottom: 58px;
  }
  .backBtn {
    color: var(--primary-clr) !important;
    padding: 0px;
    font-weight: 500;
  }
  .backBtn span {
    font-weight: inherit;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .formBg {
    background-color: var(--white-clr);
    box-shadow: 0px 3px 6px #00000029;
    padding-block: 60px;
  }
  
  .formBg h3 {
    color: var(--primary-clr);
    font-size: 35px;
  }
  .formBg .headAndDropDown{
    border-bottom: 1px solid var(--dashboard-text-gray-clr);
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;


  }
  .formBg h4 {
    color: var(--dashboard-text-gray-clr);
    font-size: 35px;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0px;
  }
  .formRow {
    row-gap: 30px;
  }
  .btnDiv {
    display: flex;
    justify-content: center;
  }
  .btnDiv .btn {
    padding: 16px 99px;
  
  }
  