.page {
}
/* Hero Section */
.heroSection {
  min-height: 615px;
  padding-bottom: 80px;
  padding-top: 100px;
  position: relative;
}
.heroSection::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url(../../assets/images/agent-registeration-hero-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.heroSection::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #0000009c;
}
.heroRow {
  z-index: 2;
  position: sticky;
}
.form {
  background: var(--white-clr);
  box-shadow: 0px 5px 30px #0000004f;
  padding: 18px;
}
.form h4,
.form h6 {
  text-align: center;
  color: var(--primary-clr);
}

.form h6 {
  font-size: 22px;
}
.formRow {
  row-gap: 10px;
}

.heroContent {
}
.heroContent .logoDiv {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}
.heroContent .logoDiv img {
  width: 100%;
  height: 100%;
}
.heroContent ul {
  padding-left: 0px;
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  margin-top: 30px;
}
.heroContent ul li svg {
  flex-shrink: 0;
}
.heroContent ul li {
  display: flex;
  align-items: center;
  color: var(--white-clr);
  font-size: 18px;
  column-gap: 5px;
}
.heroContent .btn {
  margin-top: 30px;
}
.heroContent .btn svg {
  font-size: 20px;
}
.heroContent h3 {
  color: var(--white-clr);
}
.heroContent h3 span {
  display: block;
  color: var(--secondary-clr);
  font-size: inherit;
}
/* End of Hero Section */

/* powerfullSection */
.powerfullSection {
  padding-block: 64px;

}
.powerfullSection h2 {
  color: var(--secondary-clr);
}
.powerfullSection .imgDiv {
  width: 100%;
  height: 100%;
}
.powerfullSection .imgDiv img {
  width: 100%;
  height: 100%;
}
.powerfullSection .powerFullItemRow{
    row-gap: 20px;
    margin-top: 40px;

}
.bgImage{
  background-image: url(../../assets/images/powerfull-section-image.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  direction: ltr;

}
.powerfullSection .powerFullItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 126px;
}
.powerfullSection .powerFullItem .imgDiv {
  width: 54px;
  height: 100%;
}
.powerfullSection .powerFullItem .imgDiv img {
  width: 100%;
  height: 100%;
}
.powerfullSection .powerFullItem p {
  text-align: center;
  color: var(--text-black-clr);
  font-weight: 600;
  margin-bottom: 0px;
}

/* powerfullSection */

/* Keep in touch section */
.keepInTouchSection {
}
.keepInTouchSection .img{
    width: 100%;
    height: 300px;
}
.keepInTouchSection .img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.keepInTouchSection ul {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-left: 0;
  margin-top: 20px;
}
.keepInTouchSection ul li {
  display: flex;
  align-items: center;
}
.keepInTouchSection ul li p {
  font-weight: 600;
  margin-bottom: 0px;
}
.keepInTouchSection ul li .mainImgDiv{
  flex-shrink: 0;
  width: 70px;
}
.keepInTouchSection ul li .imgDiv {
  height: 40px;
  width: 40px;
  margin-right: 5px;
  margin-right: auto;
}

.keepInTouchSection ul li .imgDiv img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Events Managements */
.eventsManagementSection {
  padding-block: 80px;
  position: relative;
}
.eventsManagementSection::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url(../../assets/images/events-managment-section-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.eventsManagementSection::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #0000001a;
}
.eventsManagementSection h2,
.eventsManagementSection h5 {
  text-align: center;
}
.eventsManagementSection h2 {
  color: var(--secondary-clr);
}
.eventsManagementSection h5 {
  color: var(--white-clr);
}

.eventsManagementSection .eventsRow {
  position: sticky;
  z-index: 2;
}
.eventsManagementSection .eventsFeaturesDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}
.eventsManagementSection .eventsFeaturesDiv p {
  margin-top: 20px;
  color: var(--white-clr);
  font-weight: 600;
}
.eventsManagementSection .eventsFeaturesDiv .featureMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eventsManagementSection .eventsFeaturesDiv .featureMainDiv .circleDiv {
  width: 176px;
  height: 176px;
  /* UI Properties */
  border: 2px solid #ffffff;
  border-radius: 176px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Our Packages */
.packagesSection {
  padding-block: 60px;
}
.packagesSection .packagesRow{
    row-gap: 40px;
}
.packagesSection .headings {
  margin-bottom: 40px;
  text-align: center;
}
.packagesSection .headings h2 {
  color: var(--secondary-clr);
}


@media screen and (max-width:1400px) {
    .eventsManagementSection .eventsFeaturesDiv .featureMainDiv .circleDiv {
        width: 160px;
        height: 160px;
        border-radius: 160px;
        padding: 30px;
      }
      
}
@media screen and (max-width:1220px) {
    .eventsManagementSection .eventsFeaturesDiv .featureMainDiv .circleDiv {
        width: 150px;
        height: 150px;
        border-radius: 150px;
        padding: 20px;
      }
      
}
@media screen and (max-width:1024px) {
    .eventsManagementSection .eventsFeaturesDiv .featureMainDiv .circleDiv {
        width: 140px;
        height: 140px;
        border-radius: 140px;
      }
      
}
@media screen and (max-width:992px) {
    .eventsManagementSection .eventsFeaturesDiv{
        flex-wrap: wrap;
        justify-content: center;
        
    }
    .eventsManagementSection .eventsFeaturesDiv .featureMainDiv{
        flex-basis: 50%;
    }
    .eventsManagementSection .eventsFeaturesDiv .featureMainDiv .circleDiv {
        width: 140px;
        height: 140px;
        border-radius: 140px;
      }
      .eventsManagementSection .eventsFeaturesDiv .featureMainDiv .circleDiv img{
        width: 80%;
        height: auto;
        flex-shrink: 0;
      }
      .heroRow {
        row-gap: 20px;
      }
      .bgImage{
        background: unset;
      }
  
      
}