.page {
  background-color: var(--page-bg-color);
}
.bgGray {
  padding-bottom: 60px;
}
.loader{
  min-height:calc(100vh - 457px) !important;
  height: auto;
}

.filtersDiv {
  background-color: #e1e1e1;
  border-block: 1px solid var(--ccc-clr);
  margin-bottom: 27px;
}

.mapList {
  row-gap: 15px;
}

.mainMap {
  height: 100%;
}
.mapClass {
  min-height: 600px;
  height: calc(100vh - 330px);
  max-height: 1000px;
}
.mapLisingCards {
  height: calc(100vh - 330px);
  overflow-y: auto;
}
.h100 {
  height: 100%;
}
.filters {
  background-color: initial;
  padding: 13px 0px;
}

.mainRow {
  padding-bottom: 10px;
  row-gap: 30px;
  min-height:calc(100vh - 445px);
}

.innerRow {
  row-gap: 15px;
}

.bannerRow {
  row-gap: 30px;
  height: 100%;
}

.tabsDiv {
  background-color: var(--primary-clr);
  /* padding-block: 16px; */
  margin-bottom: 50px;
}

.tabsDiv > div {
  /* padding-block: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.tabsDiv p {
  color: var(--white-clr);
  margin-bottom: 0px;
}
.tab {
  width: 170px !important;
}

.tab span {
  color: var(--white-clr) !important;
}
.verticalBanner {
  position: sticky;
  top: 130px;
}

.horizontalBanner {
  /* height: 250px; */
}
.horizontalBanner img {
  /* height: 250px; */
  object-fit: contain;
}
.pagination {
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .mapLisingCards {
    height: auto;
    overflow-y: unset;
  }
}

@media (max-width: 992px) {
  .col1 {
    width: 100%;
  }
  .col2 {
    width: 100%;
  }
  .bannerCol {
    display: none;
  }
  .tab span {
    flex-shrink: 0;
  }
  .mapClass {
    min-height: 500px;
  }
}

@media (max-width: 767px) {
  .tab {
    width: 60px !important;
    display: flex;
    justify-content: center;
  }
  .tab span {
    display: none;
  }
  .mapClass {
    min-height: 400px;
  }
}
@media (max-width: 520px) {
  .tabsDiv {
    padding-block: 10px;
  }
  .tab {
    width: 50px !important;
  }
}
@media (max-width: 430px) {
  .tab {
    width: 40px !important;
  }
  .tabsDiv p {
    font-size: 12.5px;
  }
  .mapClass {
    min-height: 350px;
  }
}
