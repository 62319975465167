.mt30{
    margin-top: 30px;
}

.contentWrap{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.contentWrap>p{
    margin: 0px !important;
    font-size: 24px;
    font-weight: 400;
}



.price{
    display: flex;
    align-items: center;
    gap: 10px;
}

.price h6{
    color: var(--primary-clr);
    font-size: 30px;
    margin: 0px !important;

}
.price p{
    color: var(--primary-clr);
    font-size: 20px;
    margin: 0px !important;
    font-weight: 400;
}

.minmax p{
    color: var(--primary-clr);
    font-size: 16px;
    margin: 0px !important;
    font-weight: 400;
}