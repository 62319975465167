.main_conti {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.main_conti[data-direction-col='true'] {
  flex-direction: column;
  row-gap: 20px;
}
.sidebar_main {
  width: 230px;
  flex-shrink: 0;
}
.main_conti[data-direction-col='true'] .sidebar_main {
  width: 100%;
}
.profile_img {
  width: 50px;
  height: 50px;
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.profile_name :is(h5, p, h6) {
  margin-bottom: 0;
}

.profile_name h5 {
  font-size: 18px;
}
.profile_name h6 {
  font-size: 14px;
  color: #8e8e8e;
}
.profile_name {
  margin-inline-start: 10px;
}
.profile_head {
  display: flex;
  background: #dfdfdf;
  align-items: center;
  height: 70px;
  padding-inline-start: 15px;
}
.sidebar_main > div {
  margin-inline-end: 15px;
}
.item_lists {
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 13px 0px 10px;
  border-top: 1px solid #dfdfdf;
}
.item_lists > p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
