.HeaderNotification {
  width: 450px;
  height: 400px;
  /* background-color: white; */
  background-color: rgb(244, 236, 236);

  box-shadow: 0 0 4px 0px #0000004a;
  /* padding: 0px 0px 16px 0px; */
  border-radius: 12px;
  overflow-y: scroll;
}

.ImgDiv {
  width: 90px;
  height: 90px;
  /* border-radius: 50px; */
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c8c8c84a;
  object-fit: cover;
}

.HeaderNotificationInnerDiv {
  display: flex;
  border-bottom: 1px solid var(--primary-clr);
  padding: 4px 7px;

  background-color: rgb(244, 236, 236);

  cursor: pointer;
  transition: background 0.5s;
}

.HeaderNotificationInnerDiv:hover {
  background: #ffa7a74a;
}

.contentDiv {
  margin-left: 12px;
  width: 100%;
}

.contentDiv h6 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 6px;
  font-size: 16px;
}

.contentDiv p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color-gray);
  margin-bottom: 0px;
  font-size: 16px;
}

.mb16 {
  /* margin-bottom: 16px; */
}

.viewAll {
  text-align: center;
  color: #000000;
  cursor: pointer;
  margin-bottom: 0px;
  border-top: 1px solid rgb(233, 199, 199);
  padding-block: 10px;
  position: sticky;
  bottom: 0;
  background-color: #ffff;
  transition: all 0.5s;
}

.viewAll:hover {
  background-color: rgb(236, 236, 236);
}

.notificationHeader {
  /* margin-bottom: 10px; */
  padding-inline-start: 16px;
  background-color: rgb(214, 203, 203);
  color: #000000;
  padding-block: 12px;
  display: flex;
  gap: 5px;
  /* justify-content: space-between; */
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.notificationHeader h6 {
  margin-bottom: 0px;
}
.notificationHeader p {
  margin-bottom: 0px;
}

.titleDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.notificationSkeleton {
  gap: 6px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  position: relative;
}

.noData {
  height: 300px !important;
}

.titleDiv p {
  width: 100%;
  /* text-align: right; */
  font-size: 14px;
}

.content p {
  font-size: 15px;
}
.content span {
  font-weight: 500;
}

.time p {
  font-size: 12px;
  text-align: right;
  font-weight: 600;
}
.price {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
}
.price p:first-child {
  color: var(--primary-clr);
  margin: 0px !important;
  font-size: 20px;
  font-weight: 600;
}
.price p:last-child {
  color: #6f6f6f;
  margin: 0px !important;
  font-size: 11px;
}

.tab_main {
  line-height: 1 !important;
  height: unset !important;
  overflow-x:scroll ;
  font-size: 13px !important;
  gap: 0px !important;
}


.tab_main li {
  width: auto;
}
.tab_main li span{
}