.Container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  color: var(--text-black-clr);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
}

.Container[data-variant='dashboard'] .label{
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
  
}

.disabled {
  color: var(--disabled-label-clr);


}

.reactSelect {}

/* On Hover */
.dropdown:hover {
  border: 1px solid black !important;
}

/* on Focus */
.dropdown:focus {
  border: 1px solid black;
}

/* On Active */
.dropdown:active {
  border: 1px solid black;
}

/* On Disabled */
.dropdown:disabled {
  background-color: #f6f6f6;
  color: #b5b5b5;
  border: 1px solid #e5e5e5;
}

.noBorder {
  border: 0px solid #e5e5e5;
}

.arrow {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

/*  React Select */
.leftIconBox {
  position: absolute;
  left: 20px;
  top: 55%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

.dropdownContainer {
  position: relative;
}