.iconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #868686;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.header {
  background: var(--primary-clr) !important;
}
.content {
}

.content h4 {
  text-align: center;
  font-size: 25px;
}

.btnsBox {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
  padding-inline: 40px;
}
.yesBtn {
  border-radius: 5px;
  background-color: var(--btn-secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 10px;
  padding-inline: 43px;
  gap: 4px;
}
.noBtn {
  border-radius: 5px;
  background-color: var(--btn-color-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 10px;
  padding-inline: 43px;
  gap: 4px;
}

/* For Header */
.iconDiv {
  margin-bottom: 6px;
}

.modalHeader {
  padding: 0px;
}

.headingText {
  font-size: 24px;
  margin-bottom: 0px !important;
  /* color: #0000 !important; */
}
.message {
  font-size: 18px;
  font-weight: 500;
  color: var(--dashboard-text-color);
}

.aCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 75%;
  margin: auto;
}

/* .iconBtn{
  height: 20px;
  width: 20px;
} */
/* .content {}

.content h4 {
  text-align: center;
  font-size: 25px;
  font-family: 'Open-Sans-regular';
}

.btnsBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.yesBtn {
  background-color: green;
  min-width: 90px;
}

.noBtn {
  background-color: red;
  min-width: 90px;
}

.iconDiv {
  margin-bottom: 6px;
}

.modalHeader {
  padding: 0px;
}

.header {

  font-size: 32px;
  color: var(--white-clr);
  font-family: 'Open-Sans-bold';
}

.aCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 75%;
  margin: auto;
} */
