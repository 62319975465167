.tab_main {
  border-bottom: 2px solid #00a997;
  box-shadow: unset;
  margin-bottom: 20px;
}
.page {
  min-height: calc(100vh - 128px);
  padding-bottom: 50px;
}
.delete_div {
  background-color: #fff;
  border: 0;
}
.tab_main li {
  width: 210px;
}
.sort_div {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.sort_div p {
  margin-right: 10px !important;
}
.delete_div {
  /* box-shadow: 0px 0px 3px #524f4f; */
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid #d2d2d2;
  margin-bottom: 10px;
  justify-content: space-between;
}
.delete_div p:nth-child(1) {
  margin-left: 15px;
}
.delete_div p {
  margin-bottom: 0;
  margin-right: 55px;
}
.bell_div {
  display: flex;
}
.search_div {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 10px;
  border: 1px solid #d0caca;
}
.bell_div {
  display: flex;
}
.icon_div {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-clr);
}
.icon_div1 {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right_div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.left_div{
    margin-left: 10px;
}
.left_div :is(p, h6) {
  margin: 0;
}
