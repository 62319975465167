.Container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

}

.labelText {
  color: var(--input-label-color);
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;

}

.inputBox {
  height: 100%;
  padding: 15px 16px;
  width: 100%;
  border: 1px solid var(--input-border-clr);
}

.noBorder {
  border: none;
}

.inputPassContainer {
  display: flex;
  position: relative;
  background-color: var(--white-clr);
  border-radius: 5px;
  border: none;
}

.inputPassContainer input {
  font-size: 16px;
  color: var(--text-black-clr);
  border-radius: inherit;
  background-color: inherit;
  font-weight: 400;

}
.inputPassContainer input:disabled{
  background-color: var(--disabled-input-clr);
}


.inputPassContainer input::placeholder {
  color: var(--placeholder-clr);
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-weight: 400;

}

.inputPassContainer .passwordIcon {
  position: absolute;
  inset-inline-end: 15px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #cacaca;
}

.errorText {
  color: red;
}

.disabled {
  color: var(--disabled-label-clr);
}

.leftIconBox {
  position: absolute;
  left: 20px;
  top: 50%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}

.Container[data-variant='dashboard'] .labelText{
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
  
}
.Container[data-variant='dashboard'] .inputBox{
  background-color: var(--dashboard-input-bg-clr);
  border-radius: 6px;
  border: none;
  
}


@media (max-width: 991px) {
  

  /* .inputBox {
    padding: 12px;
  } */

  .inputPassContainer input {
    font-size: 15px;
  }
}