.propertyMain {
  width: 100%;
  /* margin-bottom: 20px; */
  background: white;
  /* box-shadow: 0px 0px 14px #00a99733; */
  display: flex;
  position: relative;
  box-shadow: var(--card-shadow);
  border-radius: 8px;
  overflow: hidden;

}

.sliderContainer {
  /* width: 50%; */
  width: 534px;
}

.sliderContainer > div {
  height: 100%;
}
.sliderContainer > div > div {
  height: 100%;
}

.sliderContainer > div > div > div {
  height: 100%;
}

.propertyImageContainer {
  aspect-ratio: 4 / 3;
  width: 100%;
}

.propertyImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 0;
}


.rightContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  width: calc(100% - 534px);
  cursor: pointer;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.priceContainer h6 {
  font-size: 19px;
  margin-bottom: 0;
}
.priceContainer span:nth-child(2) {
  color: var(--primary-clr);
  font-size: 22px;
  font-weight: 600;
  margin-right: 6px;
}

.logoContainer {
  width: 100px;
  height: 50px;
}
.logoContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.titleContainer h6 {
  height: 42px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: #494949;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.location {
  display: flex;
  align-items: center;
}

.location span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 12.5px;
  margin-left: 5px;
}

.propertyInfoContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 3px;

}
.infoIcons{
  width: 110px;
  
}
.propertyDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 110px;
}
.propertyDetail p {
  margin: 0px !important;
  font-size: 12px;
  color: #656565;
  font-weight: 500;
}



/* bottomStrip */

.bottomStrip {
  border-top: 1px solid #eee;
  padding-top: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.socialIconsWrap1 {
  display: flex;
  align-items: center;
  gap: 12px;
  /* position: absolute; */
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
}
.socialIcons1 {
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}
.midIcon {
  border-inline: 1px solid #e6eaeb;
  padding-inline: 12px;
}

.iconWrap {
  display: flex;
  gap: 20px;
}
.iconsBottom{
  height: 25px;
  width:25px;
}
.iconsContainer {
  display: flex;
  width: 45%;
  transform: translateX(-23px);
}

.iconsContainer div {
  cursor: pointer;
  width: 33.3%;
  text-align: center;
  gap: 10px;
}

.iconsContainer div:nth-child(2) {
  position: relative;
}

.iconsContainer div:nth-child(2)::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5;
  width: 1px;
  height: 30px;
  background-color: #eee;
  z-index: 99;
}
.iconsContainer div:nth-child(2)::after {
  content: '';
  position: absolute;
  top: 0;
  right: -5;
  width: 1px;
  height: 30px;
  background-color: #eee;
  z-index: 99;
}

.bottomStripRight {
  display: flex;
  width: 20%;
  justify-content: end;
  gap: 10px;
}

.bottomStripRight div {
  width: 50%;
  text-align: center;
  cursor: pointer;
}

.bottomStripRight div:nth-child(1) {
  position: relative;
}
.bottomStripRight div:nth-child(1)::before {
  content: '';
  position: absolute;
  right: -5;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #eee;
}

.featuredPremiumContainer {
  position: absolute;
  top: 7%;
  inset-inline-start: 2%;
}

.featuredPremiumContainer span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px;
}

.featuredIco {
  color: #000000;
  background-color: var(--secondary-clr);
}

.premiumIco {
  color: #FFFF;
  background-color: #000000;
}


/* @media (max-width:767px) {
  .sliderContainer {
    width: 100% !important;
  }
  .rightContainer{
    width: 100% !important;
  }
  .propertyMain {
   flex-direction: column;
  }
  
.propertyImageContainer {
  height: 250px;
}
.iconsContainer {
  display: flex;
  width: 45%;
  transform: translateX(-23px);
}

} */
@media (max-width:1092px) {
  .iconsContainer {
    display: flex;
    width: 50%;
    transform: translateX(-23px);
    padding-inline-start: 20px;
  }
  
  }
@media (max-width:767px) {
  .sliderContainer {
    width: 100% !important;
  }
  .propertyMain {
   flex-direction: column;
  }
  
  .propertyImageContainer {
    height: 250px;
  }
.iconsContainer {
  display: flex;
  width: 45%;
  transform: translateX(-23px);
}
.rightContainer{
  width: 100% !important;
  gap: 10px;
}

}




@media (max-width:400px) {
  .priceContainer h6 {
    font-size: 16px;
  }
  .priceContainer span:nth-child(2) {
    color: var(--primary-clr);
    font-size: 18px;
    
  }
  
  .logoContainer {
    width: 80px;
    height: 40px;
  }

  
  .titleContainer h6 {
    height: 42px;
    font-size: 13px;
    
  }
  
 
  
  .location span {
    
    font-size: 10px;
  }
  .socialIconsWrap1 {
    
    gap: 7px;
    
  }
  .socialIcons1 {
    padding-inline: 7px;
   
  }
  .midIcon {
    padding-inline: 7px;
  
  }
  .iconsBottom{
    height: 23px;
    width: 23px;
  }
  
}

@media (max-width:1270px) {
  .sliderContainer {
    width: 265px;
  }
  .rightContainer{
    width: 265px;
    width: calc(100% - 265px);
  }
  
}