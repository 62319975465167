.heroSection{
    /* min-height: 70vh; */
    height: 35rem;
    position: relative;
    
}
.heroSection picture{
    height: 100%;
    width: 100%;
}
.heroSection picture img{
    height: 100%;
    width: 100%;
    object-fit: cover;

}
.contentContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}