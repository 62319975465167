.main {
  background-color: var(--page-bg-color);
  padding-bottom: 2rem;
}

.gap {
  row-gap: 20px;
}
.image {
  width: 100%;
  /* height: 400px; */
  margin: 0px !important;
  position: relative;
  overflow: hidden;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* .head{
      padding-block: 10px;
      display: flex;
      gap: 20px;
  }
  .back{
      display: flex;
      gap: 5px;
      padding-right: 20px;
      border-right: 1px solid var(--btn-color-grey);
  }
  .back span{
      text-decoration: underline;
      font-size: 13px;
      color:#454343;
  
  } */

.mapContainer {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  /* padding: 0 20px; */
}

.mapClass {
  width: 100%;
  height: 440px;
}
.images {
  margin-bottom: 30px;
  overflow-x: clip;
}
.view_btn {
  border-radius: 0 !important;
}
.detailWrap {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid var(---border-color);
}
.listOptionsWithBrBtm {
  margin-bottom: 0px;
  padding: 0px;
  padding-block: 10px;
}
.listOptionsWithBrBtm li {
  padding-block: 5px;
  cursor: pointer;
  padding-inline: 20px;
  
}
.listOptionsWithBrBtm li[data-selected='true'] {
  color: var(--white-clr);
  background-color: var(--primary-clr);
}
.unitfloorImages {
}
.unit_floor_row {
  /* margin-top: 15px; */
  /* display: flex; */
  align-items: center;
  row-gap: 20px;
  /* border: 1px solid; */
}
.unit_floor_list_div {
  padding-inline: 20px;
  padding-block: 10px;
}
.border_unit {
  border: 1px solid var(--border-color);
}

.priceRow {
  display: flex;
  gap: 20px;
  padding-top: 5px;
  padding-bottom: 20px;
  /* border-bottom: 1px solid var(--border-color); */
  /* margin-bottom: 20px; */
}
.mainImage {
  height: 150px;
  width: 200px;
}
.mainImage img {
  height: 100%;
  width: 100%;
}
.contentMain {
  width: 100%;
  padding-right: 20px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.title p {
  font-size: 28px;
  font-weight: bold;
  margin: 0px !important;
}

.desc p {
  font-size: 22px !important;
  color: #4b4b4b;
  font-weight: 600;
  margin-block: 0px !important;
}
.price {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
}
.price p {
  color: var(--primary-clr);
  margin: 0px !important;
  font-size: 24px;

  font-weight: 600;
}

/* .description{
      margin-top: 5px;
  } */
.description p {
  font-size: 22px !important;
  color: #4b4b4b;
  font-weight: bold;
  margin: 0px !important;
}
.addressWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin-top: 10px; */
}
.addressWrap p {
  margin: 0px !important;
  color: #656565;
  font-size: 17px;
}
.openHouseWrap {
  padding-inline: 10px;
}
.openHouseWrap p {
  font-weight: bold;
  font-size: 18px;
  margin: 0px !important;
  text-align: center;
}
.dateTime {
  font-size: 10px;
  color: var(--text-dark-gray-clr);
  /* color: #656565; */
  font-weight: 500;
}

.propertyDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  /* margin-top: 5px; */
  margin-right: 20px;
}
.propertyDetail p {
  margin: 0px !important;
  color: var(--text-dark-gray-clr);
  font-weight: 500;
  font-size: 12px;
}
.propertyDetailWrap {
  /* margin-bottom: 10px; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-block: 10px;
  border-block: 1px solid var(--border-light-color);
}
.list {
  padding-bottom: 8px;
  margin-top: 10px;
}
.list > span {
  display: block;
}
.tabs_main {
  margin-bottom: 15px;
}
.tab {
  justify-content: center;
}
.overView {
  margin-top: 20px;
}
.overViewRow {
  justify-content: space-between;
}
.overviewCol {
  width: 45%;
}
.description {
  margin-top: 20px;
}

.socialIconsWrap1 {
  display: flex;
  align-items: center;
  padding-block: 10px;
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
  width: 100%;
  background-color: #ffff;
  box-shadow: var(--card-shadow);
}
.socialIconsWrap1 svg {
  cursor: pointer;
}
.socialIcons1 {
  width: 50%;
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #FFFF; */
}

.images > div {
  height: 100%;
}
.images {
  height: 440px;
}
.description p {
  margin: 0px !important;
  font-weight: 400;
  font-weight: 500;
  color: var(--text-dark-gray-clr);
  font-size: 18px !important;
  margin-top: 20px !important;
}

.floor_unit_item svg {
  color: var(--text-black-clr);
}
.floor_unit_item span:first-child {
  font-weight: 600;
  color: var(--text-black-clr);
}

.listItem span:first-child {
  font-weight: 600;
  color: var(--text-black-clr);
}
.listItem span:last-child {
  color: var(--text-dark-gray-clr);
  font-weight: 500;
}

.interior {
  margin-top: 20px;
}
.interiorList span {
  color: var(--text-dark-gray-clr);
  font-weight: 500;
  font-size: 18px;
}

.socialIconsWrap1 {
  display: flex;
  align-items: center;
  padding-block: 10px;
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
  width: 100%;
  background-color: #ffff;
}
.socialIcons1 {
  width: 50%;
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #FFFF; */
}
.borderRight {
  border-right: 1px solid #e6eaeb;
}

.clientCard {
  margin-top: 20px;
  position: sticky;
  top: 130px;
}
/* banner */
.bannerAdContainer {
  margin-top: 30px;
}

.banner {
  height: 800px;
}
.similarEvents {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.col1 {
  width: calc(100% - 270px);
}
.col2 {
  width: 270px;
}

.overlayBox {
  padding: 16px;
  width: 100%;
}

.p0 {
  padding: 0px;
}
.popoverMain {
  max-width: 200px !important;
  inset-inline-start: 0 !important;
}
.btn {
  font-size: 15px !important;
  padding: 10px 15px;
  flex-shrink: 0;
}
.btn svg {
  font-size: 18px;
}
@media (max-width: 992px) {
  .title p {
    font-size: 22px;
  }
  .mainImage {
    height: 120px;
    width: 150px;
  }
  .col1 {
    width: 100%;
  }
  .col2 {
    width: 100%;
  }
  .overviewCol {
    width: 100%;
  }
  .overViewRow {
    justify-content: center;
    row-gap: 20px;
  }
  .verticalBanner {
    display: none;
  }
  .price p {
    color: var(--primary-clr);
    margin: 0px !important;
    font-size: 20px;

    font-weight: 600;
  }
  .desc p {
    font-size: 16px !important;
    color: #4b4b4b;
    font-weight: 600;
    margin-block: 0px !important;
  }

  .addressWrap p {
    margin: 0px !important;
    color: #656565;
    font-size: 12px;
  }
}

@media screen and (max-width: 1220px) {
  .images {
    height: 340px;
  }
}
@media screen and (max-width: 1024px) {
  .images {
    height: 300px;
  }
}
@media screen and (max-width: 768px) {
  .images {
    height: 260px;
  }
  .price p {
    color: var(--primary-clr);
    margin: 0px !important;
    font-size: 20px;

    font-weight: 600;
  }
  .desc p {
    font-size: 16px !important;
    color: #4b4b4b;
    font-weight: 600;
    margin-block: 0px !important;
  }

  .addressWrap p {
    margin: 0px !important;
    color: #656565;
    font-size: 12px;
  }
  .mainImage {
    height: 90px;
    width: 150px;
  }
  .container {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .images {
    height: 240px;
  }
  .priceRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: start;
  }
  .openHouseWrap p {
    text-align: start;
  }
}

@media (max-width: 1024px) {
  .overviewCol {
    width: 100%;
  }
  .clientCard {
    position: static;
  }
  .overViewRow {
    justify-content: center;
    row-gap: 20px;
  }
}

@media screen and (min-width: 1400px) {
  .tab {
    flex: 1;
  }
}
