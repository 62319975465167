.iconButtonMain {
  padding: 12px 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.iconButtonMain p {
  margin-bottom: 0;
  color: #fff;
  font-size: 16;
  text-align: center;
}

.iconButtonActive {
  background-color: #fff;
}

.iconButtonActive svg {
  fill: var(--primary-color);
}
.iconButtonActive p {
  color: var(--primary-color);
}

.bxShadow {
  box-shadow: unset;
}


/* 
.popperDiv {
  text-align: center;
  background: var(--primary-color) !important;
  box-shadow: 0 0 8px 4px #00000014 !important;
  padding: 8px 24px 4px 24px;
}
.popperDiv::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: var(--primary-color) !important;
  top: -5px;
  transform: translate(-50%, -0%) rotate(45deg);
}
.popperDiv ul {
  padding: 0;
}

.popperDiv ul li {
  font-size: 14px;
  color: var(--white-color);
  padding: 0 0 6px 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.popperDiv ul li:hover {
  background: var(--primary-color) !important;
}

.popper {
  inset: 0px auto auto 27px !important;
}

@media screen and (max-width: 1700px) {
  .popper {
    inset: 0px auto auto 17px !important;
  }
} */
