.agentIntroContainer {
  display: grid;
  place-items: center;
  background: white;
  padding: 20px 0;
  box-shadow: var(--card-shadow);
  height: 413px
}
.edit_btn{
  
}

.profileImageContainer {
  width: 150px;
  height: 150px;
}

.profileImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.agentIntroContainer{
  text-transform: capitalize;
}

.agentIntroContainer h5 {
  margin-top: 10px;
  margin-bottom: 0 !important;
  text-align: center;
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
  width: 85%;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.iconsContainer div {
  cursor: pointer;
  width: 33.3%;
  text-align: center;
}

.iconsContainer div:nth-child(2) {
  position: relative;
}

.iconsContainer div:nth-child(2)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 30px;
  background-color: #eee;
  z-index: 99;
}
.iconsContainer div:nth-child(2)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 30px;
  background-color: #eee;
  z-index: 99;
}

.followBtnContainer {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-clr);
  margin-top: 10px;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}
.followBtnContainer span{
  display: flex;
  align-items: center;
}
.followBtnContainer svg {
  margin-right: 10px;
}

/* aboutMeContainer */
.aboutMeContainer {
  background: white;
  padding: 25px;
  box-shadow: var(--card-shadow);
  /* height: 100%; */
  overflow-y: auto;
  max-height: 472px;
  border-bottom: 20px solid #fff;
}

.aboutMeContainer::-webkit-scrollbar {
  display: none;
}

.companyLogoAndShareContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.companyLogoContainer {
  width: 200px;
  height: 91px;
  cursor: pointer;
}

.companyLogoContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.shareLogoContainer {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  align-items: center;
  width: 73px;
}

.shareLogoContainer svg {
  cursor: pointer;
}

.companyInfo p {
  font-weight: 400;
  font-size: 18px;
}

.agentDetailsContainer {
  padding: 20px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.agentDetails {
  display: flex;
  align-items: center;
}

.agentDetails > :nth-child(1) {
  font-weight: 600;
  font-size: 18px;
}

.agentDetails > :nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  color: var(--primary-clr);
  text-transform: capitalize;
}

.agentDetails:nth-child(3) p {
  margin-bottom: 0 !important;
}

.descriptionContainer {
  padding: 20px 0;
}

.descriptionContainer h5 {
  position: relative;
  width: fit-content;
  margin-bottom: 15px;
}

.descriptionContainer h5::before {
  content: '';
  position: absolute;
  bottom: -10px;
  width: 80%;
  height: 2px;
  background-color: var(--secondary-clr);
}
.banner{
  height: 473px;
}
.banner img{
  object-position: top center;

}
/* bannerAdContainer */
.bannerAdContainer {
  width: 100%;
  height: 100%;
}

.row{
  row-gap: 20px;
}


.socialIconsWrap1 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  /* position: absolute; */
  justify-content: center;
  gap: 30px;
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
}
.socialIconsWrap1 svg{
  cursor: pointer;
}
.socialIcons1 {
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}
.midIcon {
  border-inline: 1px solid #e6eaeb;
  padding-inline: 30px;
}

@media (max-width:1250px) {
  .socialIconsWrap1 {
   
    gap: 12px;
   
  }
  .socialIcons1 {
    padding-inline: 5px;
  }
  .midIcon {
    border-inline: 1px solid #e6eaeb;
    padding-inline: 20px;
  }
  
}

@media (max-width:992px) {
  .bannerAdContainer {
  display: none;
  }
  
}

