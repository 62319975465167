.link,
.link:hover {
  /* cursor: pointer; */
  /* color: black; */
}

/* 
.link > svg {
    display: block;
  } */
.tooltip{

  width: max-content !important;
}
 