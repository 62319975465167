.banner {
  background-color: var(--primary-clr);
  padding-block: 20px;
  margin-top: 30px;
}

.banner p {
  color: var(--white-clr);
  text-transform: uppercase;
  font-size: 16px;
}
.banner h6 {
  color: var(--white-clr);
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 0px;
}
.imgDiv {
  width: 280px;
  height: auto;
  margin-left: auto;
}
.imgDiv img {
  width: 100%;
  height: 100%;
}

.aCenter {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.aCenter button {
  width: max-content;
  
}
