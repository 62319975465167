.card {
  box-shadow: 1px 6px 4px 3px #e9e7e7;
}
.topBorder {
  display: flex;
  justify-content: space-around;
}
.topBorder div {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  translate: 0px -16px;
}
.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 25px;
}
.headerContent h3 {
  text-transform: uppercase;
  color: var(--white-clr);
}
.cardContent {
  /* background-color: #434a54; */
  padding: 20px 25px;
  background-color: var(--white-clr);
}

.pricingAndPeriod {
  text-align: center;
}
.pricingAndPeriod h3 {
  color: var(--text-black-clr);
}
.pricingAndPeriod h3 span {
  font-size: 16px;
}
.pricingAndPeriod p {
  margin-bottom: 0px;
  color: var(--text-black-clr);
}

.btn {
  background-color: #f97f18;
  width: 100%;
  border: 4px solid transparent;
}

.btnSelected {
  border: 4px solid #f97f18 !important;
}

.cardContent ul {
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 30px;
  overflow-y: auto;
  min-height: 400px;
}
.cardContent ul::-webkit-scrollbar {
  width: 4px;
}
.cardContent ul::-webkit-scrollbar-thumb {
  background-color: #aab2be;
  border-radius: 10px;
}
.cardContent ul::-webkit-scrollbar-track {
  background-color: #dddddd80;
  border-radius: 10px;
}
.cardContent ul li {
  color: var(--text-black-clr);
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}
.cardContent ul li svg {
  flex-shrink: 0;
  font-size: 30px;
}

@media screen and (max-width: 1200px) {
  .cardContent ul {
    max-height: 400px;
  }
}
@media screen and (max-width: 992px) {
  .cardContent ul {
    max-height: 350px;
  }
}
