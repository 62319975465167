.ul {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.ul li {
  width: 40px;
  height: 30px;
  cursor: pointer;
}
.ul li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ul li[data-selected='true'] {
  border: 1px solid var(--primary-clr);
}
