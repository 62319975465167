.main {
  /* height: 500px; */
  width: 100%;
  padding: 0px !important;
}

.main > div {
  padding: 50px 70px;
}

.header {
  border-radius: 0px;
  height: 30px;
}
.header > div {
  top: 3px;
  right: 10px;
}
.row {
  height: 100%;
}
.col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 50px;
  height: 400px;
}
.col1 h4 {
  font-weight: 600;
  color: #029a8a;
  font-size: 35px;
}
.col1 p {
  font-size: 22px;
  color: #323434;
  font-weight: 400;
}

.iconsWrap {
  display: flex;
  gap: 30px;
}
.iconsWrap p {
  font-size: 12px;
  text-align: center;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 57px;
  background-color: #029a8a;
  border-radius: 50%;
}
.sideImage {
  /* padding-top: 100px; */
}
.sideImage img {
  height: 100%;
  width: 100%;
}

.mainInfo {
}

.info {
  display: flex;
  gap: 50px;
  margin-left: 20px;
  margin-bottom: 5px;
}

.leftDetail {
  color: var(--primary-clr);
  font-weight: bold;
  text-transform: capitalize;
}

.rightDetail {
  font-weight: 400;
  color: #756e6e;
}

.addCreditPointsContainer {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  padding: 26px;
  display: flex;
  margin-top: 15px;
}

.addCreditPointsContainer button {
  align-self: self-end;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 16.5px 30px;
}

.addCreditPointsContainer > div {
  flex: 1;
}

.addCreditPointsContainer input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
