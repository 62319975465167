.main {
  background-color: var(--page-bg-color);
  /* height: 100vh; */
}
.main h5 {
  margin-block: 16px;
}
/* .customContainer {
  max-width: 75%;
} */
.container {
  background-color: #ffff;
  box-shadow: var(--card-shadow);
  padding-block: 30px;
}
.heroSec {
  height: 450px;
  min-height: 100%;
  padding: 0px;
  background-position: top center;
}
.dNone992 {
  position: relative;
}
.image {
  height: 470px;
  width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 70%;
  left: 50%;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.heroCol2 {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.heroCol2 p {
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
.heroCol2 h3 {
  color: var(--primary-clr);
  font-weight: 700;
  margin-bottom: 0px;
}
.container > h1 {
  text-align: center;
}
.stepsWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-inline: 100px;
}
.step {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.step p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  /* width: 250px; */
}
.stepIcon svg {
  height: 80px;
  width: 80px;
}
.arrowIcon {
  font-size: 60px;
  color: var(--secondary-clr);
}

.formWrap {
  padding: 40px 100px;
}
.row {
  row-gap: 15px;
}
.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.btn > button {
  padding: 8px 45px;
}
.formSection {
  margin-top: 50px;
  padding-bottom: 30px;

}
.formSection h1 {
  color: var(--primary-clr);
}
.privacyText {
  font-weight: 500;
}
.privacyText span {
  color: var(--primary-clr);
  font-weight: 600;
  text-decoration: underline;
}
@media (max-width: 1220px) {
  .step p {
    font-size: 13px;
    /* width: 250px; */
  }
  .stepIcon svg {
    height: 60px;
    width: 60px;
  }

}
@media screen and (max-width:1200px) {

  
}

@media screen and (max-width: 992px) {
  .dNone992 {
    display: none;
  }
  .stepsWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-inline: 30px;
    flex-direction: column;
    gap: 5px;
  }
  .step {
    flex-direction: column;
  }
  .stepIcon svg {
    width: auto;
    height: 50px;
  }
  .formWrap {
    padding: 40px 30px;
  }
  .arrowIcon {
    font-size: 40px;
    transform: rotate(90deg);
  }
}
@media screen and (max-width: 768px) {
  .image {
    transform: translateY(120px);
  }
}
@media (max-width: 740px) {
  .stepsWrap {
    padding-inline: 30px;
  }
  .step p {
    font-size: 10px;
    /* width: 250px; */
  }

  .formWrap {
    padding: 40px 30px;
  }
}
