/* .main{
    height: 800px;
} */
.main img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
/* .horizontal{
    height: 200px;
}
.horizontal img{
    height: 100%;
    width: 100%;
} */