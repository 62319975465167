.bg {
  min-height: calc(100vh - 243px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/login-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  position: relative;
}
.bg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1d1c1cc7;
  content: ' ';
  z-index: 1;
}
.logoDiv {
  margin-bottom: 20px;
  border-left: 2px solid var(--white-clr);
  padding-left: 20px;
  margin-top: 40px;
}

.content {
  position: static;
  z-index: 10;
  width: 600px;
}

.form {
  background: var(--page-bg-color);
  box-shadow: 0px 5px 30px #0000004f;
  padding: 20px;
  border-radius: 10px;
}
.form h4,
.form h6 {
  text-align: center;
  color: var(--primary-clr);
}

.form h6 {
  font-size: 22px;
}
.formRow {
  row-gap: 20px;
}
.form .btns {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.form .btns button {
  padding: 17.5px 20px;
}
.googleBtn{
  background: #4285F4 !important;
  padding: 15.5px 20px !important;
  width: 100% !important;
}
.googleBtn:hover{
  background: #5075c5 !important;

}
.googleBtn span{
  width: 100%;
}
.alreadyRegistered {
  font-weight: 600;
}
.alreadyRegistered a {
  color: var(--secondary-clr);
  text-decoration: none;
  font-weight: 500;
}
.link {
  text-decoration: none !important;
  color: var(--primary-clr) !important;
}

.copyRightText {
  color: var(--white-clr);
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 0px;
}
.createAccountText {
  color: var(--white-clr);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 80px;
}
.createAccountText p {
  margin-bottom: 0px;
}
.createAccountText a {
  color: var(--secondary-clr) !important;
}
.my0 {
  margin-block: 0px !important;
}

@media screen and (max-width: 992px) {
  .bg {
    min-height: calc(100vh - 220px);
  }
}
