.page {
  padding: 45px;
}
.header {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
.header .dropDownContainer {
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}
.header .dropDownContainer label {
  flex-shrink: 0;
  margin-bottom: 0px;
}
.headerBtn {
  width: max-content !important;
}
.deleteBtn {
  width: max-content !important;
  background-color: #e95a64 !important;
}
.deleteBtn:hover {
  background-color: #e95a64cc !important;
}

.heading {
  color: var(--dashboard-text-gray-clr);
}
.mb58 {
  margin-bottom: 58px;
}
