.header {
  color: var(--text-black-clr);
  display: flex;
  /* justify-content: center; */
  font-weight: bold;
  font-size: 25px;
  padding-inline: 32px;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input {
  border-radius: 20px;
}
.forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgot p {
  margin: 0px !important;
  color: #0591e8;
}

.btn {
  margin-top: 10px;
  width: 100%;
  padding-block: 15px !important;
  /* color: #FFFF; */
  /* background-color: #EE8A1D !important; */
  border-radius: 5px !important;
}
.footer {
  margin-top: 10px;
}
.footer p {
  margin: 0px !important;
}
.footer p:first-child {
  text-align: center;
}
.footer p:last-child {
  text-align: center;
  color: #ee8a1d;
  cursor: pointer;
}

.gap {
  row-gap: 10px;
}
.contactWrap p {
  margin: 0px !important;
  font-weight: 600;
}
.methodWrap {
  display: flex;
  gap: 10px;
}
