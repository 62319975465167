.page {
  background-color: var(--page-bg-color);
  min-height: calc(100vh - 128px);
  padding-bottom: 60px;
}

.row {
  row-gap: 10px;
}
.tab {
  background-color: transparent !important;
  border-bottom: 1px solid var(--primary-clr) !important;
  box-shadow: unset !important;
  height: 40px;
}
.deleteText {
  cursor: pointer;
}
.mb15{
  margin-bottom: 15px;
}

.tabLi {
  background-color: var(--white-clr);
  min-width: 250px;
  text-align: center;
}
.tabLi svg {
  font-size: 20px;
  color: var(--text-dark-gray-clr) !important;
}
.selectedTab svg {
  color: var(--white-clr) !important;
}

.box {
  background-color: var(--white-clr);
  border: 1px solid var(--text-light-gray-clr);
  padding: 20px 20px;
}
.gap20 {
  display: flex;
  column-gap: 20px;
}
.avatar {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.uploadBtn {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  column-gap: 6px;
  margin-top: 10px;
}
.uploadBtn span {
  color: var(--primary-clr);
  text-decoration: underline;
  font-size: 14px;
}
.uploadBtn svg {
  color: var(--primary-clr);
  font-size: 20px;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.cardWithCheckbox {
  display: flex;
  column-gap: 20px;
}

.delete_div {
  background-color: #fff;
  border: 0;
}

.sort_div {
  display: flex;
  align-items: center;
  margin-inline-end: 20px;
}
.sort_div p {
  margin-inline-end: 10px !important;
}
.delete_div {
  /* box-shadow: 0px 0px 3px #524f4f; */
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid #d2d2d2;
  margin-bottom: 10px;
  justify-content: space-between;
}
.delete_div p:nth-child(1) {
  margin-left: 15px;
}
.delete_div p {
  margin-bottom: 0;
  margin-right: 55px;
}
