.cardMain {
  background-color: #ffff;
  cursor: pointer;
  box-shadow: var(--card-shadow);
  border-radius: 8px;
  overflow: hidden;
}

.image {
  height: 170px;
  width: 100%;
  margin: 0px !important;
  position: relative;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.socialIconsWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 8px;
  left: 5px;
  z-index: 1;
}
.socialIcons {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}
.contentWrap {
  padding: 2px 10px;
}
.content {
  border-bottom: 1px solid #cccccc;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.price p:first-child {
  color: var(--primary-clr);
  margin: 0px !important;
  font-size: 18px;
  font-weight: 600;
}
.price p:last-child {
  color: #6f6f6f;
  margin: 0px !important;
  font-size: 11px;
}
.svgIcon {
  fill: var(--text-dark-gray-clr);
}
.svgIcon div:first-child {
  display: flex;
}
.price1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.price1 p:first-child {
  color: black;
  margin: 0px !important;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.price1 p:last-child {
  color: var(--primary-clr);
  margin: 0px !important;
  font-size: 17px;
  font-weight: 600;
}
.iconWrap {
  display: flex;
  gap: 10px;
}
.icon {
  color: var(--primary-clr) !important;
}
.description {
  margin-top: 5px;
}
.description p {
  font-size: 13px !important;
  color: #4b4b4b;
  margin: 0px !important;
  height: 35px;
  font-weight: 600;
  margin-bottom: 0;
  color: #494949;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.addressWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}
.addressWrap p {
  margin: 0px !important;
  color: #656565;
  font-size: 11px;
}

.propertyDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
}
.propertyDetail p {
  margin: 0px !important;
  font-size: 12px;
  color: #656565;
  font-weight: 500;
}
.propertyDetailWrap {
  margin-bottom: 10px;
}

.key {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
}
.key img {
  width: auto;
  height: 37px;
}
.key1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
}
.featureTag {
  position: absolute;
  padding-inline: 4px;
  border: 1px solid #000000;
  top: 8px;
  left: 8px;
  background-color: #ffff;
  color: #000000;
  font-size: 10px;
  z-index: 1;
}
.socialIconsWrap1 {
  display: flex;
  align-items: center;
  /* gap: 8px; */
  /* position: absolute; */
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
}
.socialIcons1 {
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}
.midIcon {
  border-inline: 1px solid #e6eaeb;
}

.featuredPremiumContainer {
  position: absolute;
  top: 7%;
  z-index: 1;
  inset-inline-start: 2%;
  /* border: 1px solid #232222; */
  /* background-color: #fff; */
  /* padding: 0px 8px; */
}

.featuredPremiumContainer span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px;
}

.featuredIco {
  color: #000000;
  background-color: var(--secondary-clr);
}

.premiumIco {
  color: #ffff;
  background-color: #000000;
}
