.main {
  /* height: 500px; */
  width: 100%;
  padding: 0px !important;
}

.main > div {
  padding: 50px 70px;
}
/* .main::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image:url('../../assets/images/ContactBg.png') ;
    background-size: cover;
    background-position: top center;
    object-fit: contain;
    height: 100%;
} */
.header {
  border-radius: 0px;
  height: 30px;
}
.header > div {
  top: 3px;
  right: 10px;
}
.row {
  height: 100%;
}
.col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 50px;
  height: 400px;
}
.col1 h4 {
  font-weight: 600;
  color: #029a8a;
  font-size: 35px;
}
.col1 p {
  font-size: 22px;
  color: #323434;
  font-weight: 400;
}

.iconsWrap {
  display: flex;
  gap: 30px;
}
.iconsWrap p {
  font-size: 12px;
  text-align: center;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 57px;
  background-color: #029a8a;
  border-radius: 50%;
}
.sideImage {
  /* padding-top: 100px; */
}
.sideImage img {
  height: 100%;
  width: 100%;
}

.mainButton {
  display: flex;
  justify-content: end;
}

.mainButton button {
  width: 250px;
}
