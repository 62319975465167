.page {
  padding: 45px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headingGreen {
  color: var(--primary-clr);
}

.heading {
  color: var(--dashboard-text-gray-clr);
}
.mb58 {
  margin-bottom: 58px;
}
.backBtn {
  color: var(--primary-clr) !important;
  padding: 0px;
  font-weight: 500;
}
.backBtn span {
  font-weight: inherit;
}
.mb30 {
  margin-bottom: 30px;
}
.formBg {
  background-color: var(--white-clr);
  box-shadow: 0px 3px 6px #00000029;
  padding-block: 60px;
}

.formBg h3 {
  color: var(--primary-clr);
  font-size: 35px;
}
.formBg h4 {
  color: var(--dashboard-text-gray-clr);
  font-size: 35px;
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 24px;
}
.formRow {
  row-gap: 30px;
}
.changePassBtn{
  border: 1px solid var(--7070-clr) !important;
  border-radius: 8px !important;
  color: #756E6E !important;
  padding-block: 18.5px !important;
}

.aEnd{
  display: flex;
  align-items: flex-end;
}
.btnDiv {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  margin-top: 60px;
}
.btnDiv .btn {
  padding: 16px 0px;
  min-width: 200px;
}
.btnDiv .btnCancel{
  min-width: 200px;
  background: #E0F3F1 ;
  color: #707070;

}
.btnDiv .btnCancel:hover{
  color: var(--white-clr) !important;
}
.linkAndUpload {
  display: flex;
  align-items: center;
  width: 100%;
}
.linkAndUpload span{
  padding-inline: 7px;

}
.linkAndUpload .linkInput {
  width: calc(50% - 18px);

}

.linkAndUpload .fileUpload{
  padding-block:3.5px ;
  width: calc(50% - 18px);


}
.tagline{
  font-size: 18px;
}