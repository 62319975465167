.popover {
    max-width: 100%;
    transform: none !important;
    /* inset: 0 !important; */
    top: calc(100% + 10px) !important;
    width: 100%;
    z-index: 10;
}
.overlayDiv{
    position: relative;
}