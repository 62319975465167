.radioWithLabel {
  display: flex;
  align-items: center;
}
.radioWithLabel label {
  line-height: 1;
  margin-inline-start: 8px;
  color: var(--text-black-clr);
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.radioInput {
  accent-color: var(--text-black-clr) !important;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.radioInput:hover {
  accent-color: var(--main-color) !important;
}

.labelDisabled {
  color: var(--disabled-label-clr);
}

.labelChecked {
}

.label {
}
