.page {
  background-color: var(--page-bg-color);
  padding-bottom: 2rem;
}
.mainRow{
  margin-top: 30px;
  row-gap: 30px;
}
.filters {
  display: flex;
  justify-content: flex-end;
}
.filters > div{
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

}
.filters > div label{
  margin-bottom: 0px;
}
/* Hero Section */
.heroSection {
  padding-block: 30px;
  background: var(--primary-clr);
}
.heroSection .container {
}
.heroSection .container .row {
  max-width: 85%;
  margin-inline: auto;
}
.heroSection .container .row h4 {
  color: var(--white-clr);
}
.heroSection .container .row > div {
  display: flex;
  align-items: center;
}
.heroSection .container .row .imgDiv {
  width: auto;
  height: 200px;
}
.heroSection .container .row .imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.valueBox {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
  border: 2px solid var(--primary-clr);
}

.range {
  font-size: 13px;
  color: var(--text-dark-gray-clr);
  /* margin-top: -10px; */
}
.range span {
  font-weight: 500;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.head h6 {
  font-size: 18px;
  margin-bottom: 0px;
}

.calculatorHeader {
  background-color: var(--primary-clr);
  padding: 20px 20px;
}
.calculatorHeader h5 {
  margin-bottom: 0px;
  color: var(--white-clr);
}
.calculatorMain {
  background-color: var(--white-clr);
  padding: 20px 20px;
}

.costValue {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.costValue p {
  font-size: 30px;
  font-weight: 500;
  margin: 0px !important;
  color: var(--text-light-gray-clr);
}
.costValue h6 {
  font-size: 20px;
  font-weight: 600;
  margin: 0px !important;
}
.costValue p[data-color='primary'] {
  color: var(--primary-clr);
}

.tableMain{
  height: 645px;
  background-color: var(--white-clr);
  overflow-x: auto;
}

.table{
  position: sticky;
  z-index: 0;
  max-width: 1000px !important;
}
.tableBody{
  background-color: var(--white-clr);
  /* height: 500px; */
}
.tableBody .imgDiv{
  width:auto;
  height: 50px;
}
.tableBody .imgDiv img{
  width:100%;
  height: 100%;
  
}
.tableRow{
  background-color: var(--white-clr);
  flex-shrink: 0;
}
.thead{
  background-color: var(--primary-clr);
}
.thead .tableRow th{
  background-color: var(--primary-clr) !important;
  color: var(--white-clr);

}
.thead .tableRow th:not(:first-child){
  border-left: 1px solid  #a8a8a885;

}
.tableBody .tableRow td:not(:first-child){
  border-left: 1px solid  #a8a8a885;

}
.tableBody .tableRow td{
  border-bottom: 1px solid  #a8a8a885;
}
.tableBtn{
  background-color: transparent;
  border: 1px solid var(--primary-clr);
  color: var(--primary-clr);
  padding: 6px 10px;
  font-size: 15px;
}
.tableBtn:hover{
  color: var(--white-clr);
}
.imgDiv{
  width: 90%;
  height: auto;
}
.imgDiv img{
  width: 100%;
  object-fit: contain;
}

.loader{
  min-height: 450px !important;
  
}