.tabs {
  padding-left: 0px;
  display: flex;
  align-items: center;
  background-color: #00000073;
  width: max-content;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 41px;
  margin-bottom: 0px;
}

.tabs li p {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  width: max-content;
  padding-bottom: 4px;
  color: var(--white-clr);
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tabs li:nth-child(1) {
  border-right: 1px solid var(--text-light-gray-clr);
}

.tabs li span {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.tabs li {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 170px;
}

.tabs li svg {
  flex-shrink: 0;
}

.tabs li p[data-selected=true] {
  color: var(--primary-clr);
  border-bottom: 2px solid var(--primary-clr);
}

.dropdownBox {
  height: 51px;
  background-color: var(--white-clr);
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  column-gap: 10px;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  /* box-shadow: 0.26px 0.97px 3px #00010117; */
  border: 1px solid var(--input-border-clr);
}
.dropdownBox > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdownBox span {
  color: var(--text-black-clr);
  font-weight: 500;
  text-transform: capitalize;
}

.dropdownBox .icon {
  color: var(--secondary-clr);
  flex-shrink: 0;
}

/* otherOptions */
.otherOptions {
  background-color: #00000073;
  padding: 20px 25px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.overlayBox {
  background-color: var(--white-clr);
  padding: 16px;
  box-shadow: 6px 5px 5px 2px #0000002e;
  width: 100%;
  min-width: 250px;
  max-height: 400px;
  overflow-y: auto;
}

.p0 {
  padding: 0px;
}

.spBtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.btnMaxContent {
  width: calc(50% - 10px) !important;
  padding: 14px 5px !important;
  border: 1px solid var(--primary-clr) !important;
  font-size: 14px;
}
.clearBtn, .clearBtn:hover{
  width: calc(50% - 10px);
  padding: 14px 5px !important;
  background-color: var(--white-clr) !important;
  color: var(--primary-clr) !important;
  border: 1px solid var(--primary-clr) !important;
  font-size: 14px;
}

.linkRed {
  color: var(--error-clr);
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
}

.headWithIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headWithIcon svg {
  color: var(--secondary-clr);
  font-size: 20px;
}

.headWithIcon h6 {
  margin-bottom: 0px;
}

.row > div {
  flex: 1;
}
.row {
  gap: 8px;
}
.innerRow{
row-gap: 16px;
}
/* Property Type */
.propertyTypeTabs {
  display: flex;
  align-items: center;
  padding-left: 0px;
  border-bottom: 1px solid var(--text-light-gray-clr);
  margin-bottom: 0px;
  user-select: none;
}

.propertyTypeTabs li {
  width: 50%;
  position: relative;
}

.propertyTypeTabs li:nth-child(2) {
  border-left: 1px solid var(--text-light-gray-clr);
}

.propertyTypeTabs li span {
  color: var(--text-black-clr);
  font-weight: 500;
  cursor: pointer;
  padding-block: 7px;
  width: max-content;
  display: block;
  margin: 0 auto;
}

.propertyTypeTabs li span[data-selected='true'] {
  color: var(--primary-clr);
  font-weight: 600;
  position: relative;
}

.propertyTypeTabs li span[data-selected='true']::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-clr);
}

.propertyTypeOptions {
  padding-left: 0px;
  margin-bottom: 0px;
}

.propertyTypeOptions li {
  border-bottom: 1px solid var(--text-light-gray-clr);
  padding: 5px 10px;
  font-weight: 500;
  color: var(--text-black-clr);
}

.propertyTypeOptions li[data-selected='true'] {
  background-color: var(--primary-clr);
  color: var(--white-clr);
}

/* Property Type */

/* Price Type */
.listOptionsWithBrBtm {
  padding-left: 0px;
  margin-bottom: 0px;
}

.listOptionsWithBrBtm li {
  border-bottom: 1px solid var(--text-light-gray-clr);
  padding: 5px 10px;
  font-weight: 500;
  color: var(--text-black-clr);
  text-transform: capitalize;
}

.listOptionsWithBrBtm li[data-selected='true'] {
  background-color: var(--primary-clr);
  color: var(--white-clr);
}

/* Price Type */
.width250 {
  min-width: 250px !important;
  width: auto !important;
}

.width150 {
  min-width: 150px !important;
  width: auto !important;
}

.searchBtn {
  height: 51px;
  width: 100%;
}

.searchInput input {
  height: 51px;
}

.searchInput > div {
  border-radius: 3px;
}

.moreFiltersPopover {
  top: unset;
  /* inset: calc(100% + 10px) auto auto -100px !important; */
}

.moreFiltersPopover .overlayBox {
  /* width: 400px; */
}

.width170 {
  min-width: 170px !important;
  flex-grow: 0 !important;
  width: auto;
}
.searchBtnCol {
  min-width: 160px !important;
  flex-grow: 0 !important;
  width: auto;
}

@media screen and (max-width: 992px) {

  .width170 {
    min-width: 120px !important;
  }
  .searchBtnCol {
    min-width: 130px !important;
  }

}
@media screen and (max-width: 700px) {
  .width170 {
    min-width: 110px !important;
  }
  .searchBtnCol {
    min-width: 110px !important;
  }
 
  .searchBtn {
    padding: 0px;
  }
  
  .tabs li p {
    gap: 8px;
  }
  .tabs li span {
    font-size: 15px;
  }
  .tabs li {
    width: 150px;
  }
}

@media screen and (max-width: 600px) {
  .searchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchBtn span svg {
    float: unset;
  }
  .searchBtnCol {
    min-width: 54px !important;
  }
  .width170 {
    min-width: 54px !important;
  }
  .width170 .dropdownBox {
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .searchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
  }

  .searchBtn span svg {
    float: unset;
  }
  .searchBtnCol {
    min-width: 48px !important;
  }
  .width170 {
    min-width: 48px !important;
  }
  .width170 .dropdownBox {
    justify-content: center;
  }
  .otherOptions {
    padding: 15px;
  }
  .tabs li {
    width: 140px;
  }

}
@media screen and (max-width: 375px) {
  .searchBtnCol {
    min-width: 45px !important;
  }
  .width170 {
    min-width: 45px !important;
  }
  .width170 .dropdownBox {
    justify-content: center;
  }
  .otherOptions {
    padding: 8px;
  }

  .searchBtn {
    font-size: 18px;
  }
  .row {
    gap: 6px;
  }
  .tabs li p {
    gap: 6px;
  }
  .tabs li span {
    font-size: 14px;
  }
  .tabs li {
    width: 120px;
  }
}
