.headerMainDiv {
  /* border-bottom: 1.5px solid var(--background-color); */
  /* background-color: var(--text-black-clr);
   */
  background-color: var(--white-clr);
  /* background: transparent; */
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 30;
}

.header {
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0px;
  /* max-width: 80%;
  margin: 0 auto; */
}

.imageContainer {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.imageContainer img {
  height: 50px;
  width: auto;
}

.drawerImg {
  width: 200px;
  height: auto;
}

.drawerImg img {
  height: 100%;
  width: 100%;
}

.logo {
  width: 180px;
  height: auto;
}

.hamburger {
  font-size: 25px;
  color: var(--text-black-clr);
}

.drawerContainer {
  background: transparent linear-gradient(#ffffff 0%, #282c3d2b 100%, #ffffff 100%);
  height: 100vh;
  width: 100%;
}

.drawerLogo {
  width: 210px;
  height: auto;
}
.units {
  display: flex;
}
/* User Section */
.drawerUserSection {
  height: 175px;
  width: 100%;
  background-color: var(--primary-clr);
  object-fit: contain;
  display: flex;
  flex-direction: column;
  padding: 70px 10px 70px 20px;
  justify-content: center;
  align-items: flex-start;
}

.drawerUserSection .drawerUserImage {
  height: 75px;
  width: 75px;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.drawerUserSection .drawerUserName {
  font-size: 18px;
  font-weight: 600;
  color: var(--white-clr);
  margin-top: 10px;
  text-transform: capitalize;
}

.drawerUserSection .drawerUserEmail {
  font-size: 14px;
  color: var(--white-clr);
}

/* Listing Section */
.drawerList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.listItem {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 10px 5px 20px;
  cursor: pointer;
}

.listItem .listItemText {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-black-clr);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.listItem svg {
  fill: var(--text-black-clr);
  font-size: 18px;
  flex-shrink: 0;
}

.activeItem {
}

.activeItem .listItemText {
  color: var(--primary-clr);
}

.activeItem svg {
  fill: var(--white-clr);
}

.afterScroll {
  box-shadow: -8px -1px 3px 2px #383232;
  background-color: var(--white-clr);
}

.unitBtn {
  color: var(--text-black-clr) !important;
  padding: 5px 30px !important;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.languagesOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 0px !important;
  margin-left: 30px;
}
.languagesOptions li {
  cursor: pointer;
  line-height: 30px !important;
  padding-inline: 5px !important;
  width: 100% !important;
  font-size: 12px;
}

.languagesOptions li[data-selected='true'] {
  background-color: var(--primary-clr);
  color: var(--white-clr);
  border-radius: 3px;
}

.languagesOptions li img {
  width: 30px;
  height: 20px;
}
