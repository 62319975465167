.page {
  background-color: var(--page-bg-color);
}

.containerWrapper {
  min-height: calc(100vh - 243px);
  padding-block:50px ;
}

.buttonContainer {
  display: grid;
  place-items: center;
  margin-top: 10px;
}

.buttonContainer button {
  width: 200px;
}
.row {
  row-gap: 40px;
}
.innerRow {
  row-gap: 30px;
}
.infoLine {
  font-weight: 600;
  margin-bottom: 0px;
}
.btnCenter {
  display: flex;
  justify-content: center;
}
.btnCenter button {
  width: 300px;
}
.imgDiv {
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
}
.imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.infoSection {
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 0px;
}
.infoSection li {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  width: 100%;
  flex-basis: calc(50% - 10px);
}
.infoSection li:first-child {
  flex-basis: 100%;
}
.infoSection li img {
  flex-shrink: 0;
  object-fit: contain;
  height: 48px;
}
.infoSection li span,
.infoSection li a {
  font-size: 18px;
  word-break: break-all;
  font-weight: 600;
  color: var(--text-black-clr);
  text-decoration: none;
  width: 100%;
}

.infoSection li:nth-child(2) div {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 991px) {
  .imgDiv {
    height: 450px;
    margin-bottom: 30px;
  }
  .innerRow {
    row-gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .infoSection li {
    flex-basis: 100%;
    align-items: flex-start;
  }
  .imgDiv {
    height: 400px;
  }

  .infoSection li span,
  .infoSection li a {
    font-size: 17px;
  }
}
@media screen and (max-width: 575px) {
  .infoSection li span,
  .infoSection li a {
    font-size: 16px;
  }
}
