.tab_main {
  border-bottom: 2px solid #00a997;
  box-shadow: unset;
  margin-bottom: 20px;
}
.delete_div {
  background-color: #fff;
  border: 0;
}
.contentDiv{
  height: calc(100vh - 390px);
}
.tab_main li {
  width: 210px;
}
.sort_div {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.sort_div p {
  margin-right: 10px !important;
}
.delete_div {
  /* box-shadow: 0px 0px 3px #524f4f; */
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid #d2d2d2;
  margin-bottom: 10px;
  justify-content: space-between;
}
.delete_div p:nth-child(1) {
  margin-left: 15px;
}
.delete_div p {
  margin-bottom: 0;
  margin-right: 55px;
}
