.header {
  /* padding: 15px 0px; */
  /* padding-block: 30px; */
  padding: 0px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 30;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.firstHead {
  background-color: var(--text-black-clr);
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}
.secondHead {
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--white-clr);
}
.flexSpBtw {
  display: flex;
  justify-content: space-between;
}
.flexSpBtw ul {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 0px;
}
.flexSpBtw ul li {
  line-height: 50px;
}
.flexSpBtw ul button {
  padding: 0px;
}

.loginBtn {
  width: max-content;
  display: flex;
  align-items: center;
  font-weight: 400 !important;
}
.nav_label svg {
  margin-right: 10px;
}
.nav_label {
  font-weight: bold;
}

.afterScroll {
  box-shadow: -8px -1px 3px 2px #383232;
  background-color: var(--white-clr);
}

.logoDiv {
  cursor: pointer;
  height: 47px;
  width: auto;
}

.logoDiv img {
  width: 100%;
  height: 100%;
}

.nav_label:hover svg {
  color: #000 !important;
}
.nav_label:hover {
  color: var(--secondary-color) !important;
}
.nabarLinks {
  color: #000 !important;
  font-size: 16px !important;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 65px;
}

.navActive {
  color: var(--primary-clr) !important;
  position: relative;
  border-bottom: 3px solid var(--primary-clr);
}

.dflex {
  display: flex;
  gap: 14px;
}

.gapCustm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 40px;
}

.mx30 {
  /* margin-inline: 30px; */
}

.gap10 {
  display: flex;
  gap: 10px;
}

.profileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #929eaa;
  background-color: #d6d6d6;
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  object-position: top center;
}

.profileOverlay {
  width: 100%;
  top: 100px !important;
  display: flex;
  justify-content: flex-end;
  transform: none !important;
  inset: 100px auto auto -181px !important;
}

.profileOverlay > div {
  width: 200px;
  /* height: 100%; */
  background-color: white;
  box-shadow: 0 0 4px 0px #0000004a;
  padding: 12px 12px 0 12px;
  border-radius: 12px;
  text-align: center;
  position: fixed;
}

.overlayLink {
  font-size: 16px !important;
  color: var(--main-color) !important;
  padding: 2px 12px 16px 12px !important;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Poppins-regular';
  cursor: pointer;
  margin-bottom: 0px;
}

.logoHeader {
  color: var(--white-clr);
  font-family: 'Poppins-medium';
  font-size: 54px;
  cursor: pointer;
  margin-bottom: 0;
}

.btn {
  /* width: 178px; */
  height: 40px !important;
  padding: 10px 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;
}
.btn:hover {
  background-color: var(--secondary-color);
  color: black;
}

/* Icon With Count */
.iconWithCount {
  position: relative;
  cursor: pointer;
}
.iconWithCount svg {
  color: var(--white-clr);
}
.iconWithCount span {
  position: absolute;
  right: -2px;
  top: -8px;
  background-color: var(--icon-count-clr);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: var(--white-clr);
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.aCenter {
  display: flex;
  align-items: center;
}

/* Icon With Count */

.notifyoverlayDiv {
  top: 10px !important;
  left: -20px !important;
  z-index: 35;
}
.overlay {
  top: 12px !important;
}

.sidebar_main {
  width: 230px;
  flex-shrink: 0;
}
.profile_img {
  width: 50px;
  height: 50px;
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
}
.profile_name :is(h5, p, h6) {
  margin-bottom: 0;
}

.profile_name h5 {
  font-size: 18px;
}
.profile_name h6 {
  font-size: 14px;
  color: var(--primary-clr);
}
.profile_name {
  margin-left: 10px;
}
.profile_head {
  display: flex;
  background: #dfdfdf;
  align-items: center;
  height: 70px;
  padding-left: 15px;
}
.sidebar_main > div {
  margin-right: 0px;
}
.item_lists {
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 13px 0px 10px;
  border-top: 1px solid #dfdfdf;
}
.item_lists > p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.languagesOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 0px !important;
}
.languagesOptions li{
  cursor: pointer;
  line-height: 30px !important;
  padding-inline:20px !important;
  width: 100% !important;


}
.languagesOptions li[data-selected=true]{
  background-color: var(--primary-clr);
  color: var(--white-clr);
}
.languagesOptions li img {
  width: 30px;
  height: 20px;
}

@media (max-width: 1220px) {
  .profileOverlay {
    inset: 85px auto auto -30px !important;
  }

  .mx30 {
    /* margin-inline: 20px; */
  }

  .btn {
    width: 150px;
    height: 50px;
    padding: 0px !important;
  }
}

@media (max-width: 1080px) {
  .profileImg {
    width: 40px;
    height: 40px;
  }

  .profileOverlay {
    inset: 75px auto auto -30px !important;
  }
}

@media screen and (max-width: 1024px) {
  .nabarLinks {
    font-size: 15px !important;
  }
  .gapCustm {
    gap: 35px;
  }
}
