.row {
    gap: 8px;
  }

  .headWithIcon {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .headWithIcon svg {
    color: var(--secondary-clr);
    font-size: 20px;
  }
  
  .headWithIcon h6 {
    margin-bottom: 0px;
  }

  .btnMaxContent {
    width: calc(50% - 10px) !important;
    padding: 14px 5px !important;
    border: 1px solid var(--primary-clr) !important;
    font-size: 14px;
  }
  .clearBtn, .clearBtn:hover{
    width: calc(50% - 10px);
    padding: 14px 5px !important;
    background-color: var(--white-clr) !important;
    color: var(--primary-clr) !important;
    border: 1px solid var(--primary-clr) !important;
    font-size: 14px;
  }
  
.spBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }