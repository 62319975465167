.main {
  background-color: var(--page-bg-color);
  /* height: 100vh; */
}
.mainContainer{
  height: 440px;
}
.gap {
  row-gap: 20px;
}
.image {
  width: 100%;
  /* height: 400px; */
  margin: 0px !important;
  position: relative;
  overflow: hidden;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* .head{
        padding-block: 10px;
        display: flex;
        gap: 20px;
    }
    .back{
        display: flex;
        gap: 5px;
        padding-right: 20px;
        border-right: 1px solid var(--btn-color-grey);
    }
    .back span{
        text-decoration: underline;
        font-size: 13px;
        color:#454343;
    
    } */
.carouselMain{
  
}
.mapContainer {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  /* padding: 0 20px; */
}
.mapClass {
  width: 100%;
  height: 440px;
}
.images {
  margin-bottom: 50px;
  overflow-x: clip;
}
.view_btn {
  width: 20% !important;
  border-radius: 0 !important;
  margin-bottom: 20px;
}
.detailWrap {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid var(---border-color);
}
.unit_floor_row {
  /* margin-top: 15px; */
  display: flex;
  align-items: center;
  /* border: 1px solid; */
}
.unit_floor_list_div {
  padding-right: 20px;
}
.border_unit {
  border: 1px solid var(--border-color);
}

.priceRow {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 20px;
  /* border-bottom: 1px solid var(--border-color); */
  /* margin-bottom: 20px; */
}
.mainImage {
  height: 150px;
  width: 200px;
}
.mainImage img {
  height: 100%;
  width: 100%;
}

.title p {
  font-size: 28px;
  font-weight: bold;
  margin: 0px !important;
}

.desc p {
  font-size: 22px !important;
  color: #4b4b4b;
  font-weight: 600;
  margin-block: 0px !important;
}
.price {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
}
.price p {
  color: var(--primary-clr);
  margin: 0px !important;
  font-size: 31px;

  font-weight: 600;
}

/* .description{
        margin-top: 5px;
    } */
.description p {
  font-size: 22px !important;
  color: #4b4b4b;
  font-weight: bold;
  margin: 0px !important;
}
.addressWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin-top: 10px; */
}
.addressWrap p {
  margin: 0px !important;
  color: #656565;
  font-size: 17px;
}
.openHouseWrap {
  padding-inline: 10px;
}
.openHouseWrap p {
  font-weight: bold;
  font-size: 18px;
  margin: 0px !important;
  text-align: center;
}
.dateTime {
  font-size: 10px;
  color: var(--primary-clr);
  /* color: #656565; */
  font-weight: 500;
}

.propertyDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  /* margin-top: 5px; */
  width: 20%;
}
.propertyDetail p {
  margin: 0px !important;
  font-size: 15px;
  color: #656565;
  font-weight: 500;
}
.tabs_main {
  margin-bottom: 15px;
  justify-content: space-between;
}
.propertyDetailWrap {
  /* margin-bottom: 10px; */
  display: flex;
  flex-wrap: wrap;
  padding-block: 10px;
  border-block: 1px solid var(--border-light-color);
}

.overView {
  margin-top: 20px;
}
.overViewRow {
  justify-content: space-between;
}
.overviewCol {
  width: 45%;
}
.description {
  margin-top: 20px;
}
.images > div {
  height: 100%;
}
.description p {
  margin: 0px !important;
  font-weight: 400;
  /* font-weight:500;
        color: var(--primary-clr); */
  font-size: 18px !important;
  margin-top: 20px !important;
}

.floor_unit_item svg {
  color: var(--text-black-clr);
}
.floor_unit_item span:first-child {
  font-weight: 600;
  color: var(--text-black-clr);
}

.listItem span:first-child {
  font-weight: 600;
  color: var(--text-black-clr);
}
.listItem span:last-child {
  color: var(--primary-clr);
  font-weight: 500;
}

.interior {
  margin-top: 20px;
}
.interiorList span {
  color: var(--primary-clr);
  font-weight: 500;
  font-size: 18px;
}

.socialIconsWrap1 {
  display: flex;
  align-items: center;
  padding-block: 10px;
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
  width: 100%;
  background-color: #ffff;
}
.socialIcons1 {
  width: 25%;
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #FFFF; */
}
.borderRight {
  border-right: 1px solid #e6eaeb;
}

.clientCard {
  margin-top: 20px;
}
/* banner */
.bannerAdContainer {
  margin-top: 30px;
}

.banner {
  height: 800px;
}
.similarEvents {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media screen and (max-width:1220px) {
  .mainContainer, .mapClass {
    height: 340px;
  }  
}
@media screen and (max-width:1024px) {
  .mainContainer, .mapClass {
    height: 300px;
  }  
  .overviewCol {
    width: 100%;
  }
  .overViewRow {
    justify-content: center;
    row-gap: 20px;
  }
}
@media (max-width: 992px) {
  .overviewCol {
    width: 100%;
  }
  .overViewRow {
    justify-content: center;
    row-gap: 20px;
  }
}
@media screen and (max-width:768px) {
  .mainContainer,.mapClass {
    height: 260px;
  }  
}
@media screen and (max-width:575px) {
  .mainContainer,.mapClass {
    height: 240px;
  }  
}





