
.main {
  max-width: 95%;
  padding-block: 30px;
}

.row {
  row-gap: 20px;
}

/* membershipcard */
.memberShipWrap {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  height: 180px;
  position: relative;
  overflow: hidden;
}
.memberShipWrap::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image:url('../../../assets/images/Dashboard2.png') ;
    background-size: cover;
    background-position: top center;
    height: 110px;
}

.membership {
  color: var(--575-clr);
  font-weight: 600;
  font-size: 22px;
  /* margin: 0px !important; */
  line-height: 1;
}
.lite {
  font-size: 70px;
  color: var(--575-clr);
  font-weight: 400;
  margin: 0px !important;
  line-height: 1;
  text-transform: capitalize;
}
.valid {
  color: #444040;
  margin: 0px !important;
  line-height: 1;
}

.creditMain {
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 48px #0000000d;
  border-radius: 10px;
  height: 180px;

}

.head p {
  font-size: 23px;
  color: #444040;
}

.content {
  display: flex;
  gap: 10px;
}

.amount {
  font-size: 50px;
  color: #029a8a;
}

.purchaseBtnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.purchaseBtn {
  height: 60px !important;
  width: 200px !important;
  border-radius: 10px !important;
  font-size: 20px;
}


.contactBtn{
  display: flex;
  justify-content: end;
}