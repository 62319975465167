.cardMain {
  background-color: #ffff;
  /* height: 410px; */
  box-shadow: var(--card-shadow);
}
.feature_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  width: 90%;
  margin: 10px auto;
}
.imgDiv{
  width: 100%;
  height: 200px;
}
.imgDiv img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* object-position: top center; */
}
.feature_div p {
  margin-bottom: 5px;
}
.description {
  min-height: 50px;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.description1{
  /* min-height: 50px; */
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
}
.feature_div p:nth-child(1) {
  font-weight: bold;
}
.feature_div_last {
  width: 90%;
  margin: 10px auto;
}
.feature_div_last p:nth-child(1) {
  font-weight: bold;
  margin-bottom: 2px;
}
.icon_div {
  width: 60%;
}
.iconsContainer {
  display: flex;
}
.image {
  /* height:170px ; */
  width: 100%;
  margin: 0px !important;
  position: relative;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.socialIconsWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 8px;
  left: 5px;
  z-index: 1;
}
.socialIcons {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}
.contentWrap {
  padding: 2px 10px;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.price h6 {
  color: var(--primary-clr);
  margin: 0px !important;
  font-size: 18px;
  font-weight: 600;
}
.price p:last-child {
  color: #6f6f6f;
  margin: 0px !important;
  font-size: 11px;
}
.price1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.price1 p:first-child {
  color: black;
  margin: 0px !important;
  font-size: 16px;
  font-weight: bold;
}
.price1 p:last-child {
  color: #6f6f6f;
  margin: 0px !important;
  font-size: 17px;
}
.iconWrap {
  display: flex;
  gap: 10px;
}
.iconWrap svg {
  cursor: pointer;
}
.icon {
  color: var(--primary-clr) !important;
}
.description {
  margin-top: 5px;
}
.description p {
  font-size: 13px !important;
  color: #4b4b4b;
  font-weight: bold;
  margin: 0px !important;
}
.addressWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.addressWrap h6 {
  margin: 0px !important;
  color: #656565;
  font-size: 13px;
}

.propertyDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
}
.propertyDetail p {
  margin: 0px !important;
  font-size: 12px;
  color: #656565;
  font-weight: 500;
}
.propertyDetailWrap {
  margin-bottom: 10px;
}

.key {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 5px;
}
.key1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
}
.featureTag {
  position: absolute;
  padding-inline: 4px;
  border: 1px solid #000000;
  top: 8px;
  left: 8px;
  background-color: #ffff;
  color: #000000;
  font-size: 10px;
  z-index: 1;
}
.socialIconsWrap1 {
  display: flex;
  align-items: center;
  /* gap: 8px; */
  /* position: absolute; */
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
}
.socialIcons1 svg {
  width: 100% !important;
}
.socialIcons1 {
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  border-right: 1px solid #e6eaeb;
  background-color: #ffff;
}
.midIcon {
  border-inline: 1px solid #e6eaeb;
}
.iconsContainer {
  display: flex;
  margin: auto;
  width: 90%;
  align-items: center;
  border-top: 1px solid #e6eaeb;
  padding: 5px 0px;
}
.icon_div {
  width: 55%;
  display: flex;
  justify-content: space-between;
}
.card_footer_logo {
  width: 45%;
  display: flex;
  justify-content: right;
}
.icon_div svg {
  width: 33.33%;
}
.icon_div svg:not(:last-child) {
  border-right: 1px solid #e6eaeb;
}
.head_div {
  width: 90%;
  margin: 15px auto;
}
