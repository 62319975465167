.GiHamburgerMenu {
  font-size: 30px;
  color: var(--white-clr);
  position: absolute;
  top: 25px;
  left: 20px;
}

.sidebarDiv {
  width: 340px;
}

.contentDiv {
  width: calc(100% - 340px);
  background-color: var(--dashboard-page-bg-clr);
}

@media screen and (max-width: 1440px) {
  .sidebarDiv {
    width: 320px;
  }

  .contentDiv {
    width: calc(100% - 320px);
  }
}

@media screen and (max-width: 1220px) {
  .sidebarDiv {
    width: 280px;
  }

  .contentDiv {
    width: calc(100% - 280px);
  }
}

@media screen and (max-width: 1100px) {
  .sidebarDiv {
    width: 250px;
  }

  .contentDiv {
    width: calc(100% - 250px);
  }
}
