.page {
  background-color: var(--page-bg-color);
  min-height: calc(100vh - 128px);
  padding-bottom: 50px;
}

.row {
  row-gap: 10px;
}
.avatar_box {
  width: 200px;
  height: 200px;
}
.tab {
  background-color: transparent !important;
  border-bottom: 1px solid var(--primary-clr) !important;
  box-shadow: unset !important;
  height: 40px;
}
.tabLi {
  background-color: var(--white-clr);
  min-width: 250px;
  text-align: center;
}
.tabLi svg {
  font-size: 20px;
}

.box {
  background-color: var(--white-clr);
  border: 1px solid var(--text-light-gray-clr);
  padding: 20px 20px;
}
.gap20 {
  display: flex;
  column-gap: 20px;
}
.avatar {
  width: 180px !important;
  height: 180px !important;
  border-radius: 50%;
  overflow: hidden;
}
/* .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
} */
.uploadBtn {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  column-gap: 6px;
  margin-top: 10px;
}
.uploadBtn span {
  color: var(--primary-clr);
  text-decoration: underline;
  font-size: 14px;
}
.uploadBtn svg {
  color: var(--primary-clr);
  font-size: 20px;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 991px) {
  .gap20 {
    flex-direction: column;
    row-gap: 20px;
  }
  .flexEnd {
    margin-top: 10px;
  }
}
