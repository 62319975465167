.main {
  background-color: var(--page-bg-color);
}
.agentIntroContainer {
  display: grid;
  place-items: center;
  background: white;
  padding: 20px 0;
  box-shadow: var(--card-shadow);
}
.agentIntroContainer p {
  margin: 0px !important;
}
.profileImageContainer {
  width: 200px;
  height: 200px;
  cursor: pointer;
}

.profileImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.agentIntroContainer h5 {
  margin-top: 10px;
  margin-bottom: 0 !important;
  text-align: center;
}

.language {
  padding-block: 10px;
  border-bottom: 1px solid #eee;
  width: 85%;
  text-align: center;
}
.midContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.logo {
  height: 70px;
  width: 150px;
  cursor: pointer;
}
.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.midContent p {
  margin: 0px !important;
  font-weight: 300;
}
.language span:first-child {
  font-size: 18px;
  font-weight: 600;
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
  width: 85%;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.iconsContainer div {
  cursor: pointer;
  width: 33.3%;
  text-align: center;
}

.iconsContainer div:nth-child(2) {
  position: relative;
}

.iconsContainer div:nth-child(2)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 30px;
  background-color: #eee;
  z-index: 99;
}
.iconsContainer div:nth-child(2)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 30px;
  background-color: #eee;
  z-index: 99;
}

.followBtnContainer {
  width: 100%;
  /* height: 48px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-clr);
  margin-top: 10px;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}

.followBtnContainer svg {
  margin-right: 10px;
}

.socialIconsWrap1 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  /* position: absolute; */
  justify-content: center;
  gap: 30px;
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
}
.socialIconsWrap1 svg {
  cursor: pointer;
}
.socialIcons1 {
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}
.midIcon {
  border-inline: 1px solid #e6eaeb;
  padding-inline: 20px;
}

@media (max-width: 1150px) {
  .socialIconsWrap1 {
    gap: 5px;
  }
  .socialIcons1 {
    padding-inline: 10px;
  }
  .midIcon {
    border-inline: 1px solid #e6eaeb;
    padding-inline: 20px;
  }
  .midContent {
    text-align: center;
  }
}
