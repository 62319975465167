.mt50 {
  margin-top: 50px;
}
.loader{
  min-height: calc(100vh - 242px);
}
.main {
  background-color: var(--page-bg-color);
  /* height: 100vh; */
  padding-bottom: 2rem;
}
.images > div {
  height: 100%;
}
.images {
  height: 440px;
}
.gap {
  row-gap: 20px;
}
.tabs_main {
  margin-bottom: 15px;
}
.tab {
  justify-content: center;
}

.image {
  width: 100%;
  /* height: 400px; */
  margin: 0px !important;
  position: relative;
  overflow: hidden;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* .head{
    padding-block: 10px;
    display: flex;
    gap: 20px;
}
.back{
    display: flex;
    gap: 5px;
    padding-right: 20px;
    border-right: 1px solid var(--btn-color-grey);
}
.back span{
    text-decoration: underline;
    font-size: 13px;
    color:#454343;

} */
.images {
  margin-bottom: 30px;
  overflow-x: clip;
}

.detailWrap {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid var(---border-color);
}

.priceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 20px;
  /* border-bottom: 1px solid var(--border-color); */
  /* margin-bottom: 20px; */
}

.price {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
}
.price p {
  color: var(--primary-clr);
  margin: 0px !important;
  font-size: 24px;

  font-weight: 600;
}

/* .description{
    margin-top: 5px;
} */
.desc p {
  font-size: 22px !important;
  color: #4b4b4b;
  font-weight: 600;
  margin-block: 0px !important;
}

.list {
  padding-bottom: 8px;
  margin-top: 10px;
}
.list > span {
  display: block;
}
.addressWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin-top: 10px; */
}
.addressWrap p {
  margin: 0px !important;
  color: #656565;
  font-size: 17px;
}
.openHouseWrap {
  padding-inline: 10px;
}
.openHouseWrap p {
  font-weight: bold;
  font-size: 18px;
  margin: 0px !important;
  text-align: center;
}
.dateTime {
  font-size: 10px;
  color: var(--text-dark-gray-clr);
  /* color: #656565; */
  font-weight: 500;
}

.propertyDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  /* margin-top: 5px; */
  margin-right: 20px;
}
.propertyDetail p {
  margin: 0px !important;
  font-size: 15px;
  color: var(--text-dark-gray-clr);
  font-weight: 500;
}
.propertyDetailWrap {
  /* margin-bottom: 10px; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-block: 10px;
  border-block: 1px solid var(--border-light-color);
}

.overView {
  margin-top: 20px;
}
.overViewRow {
  justify-content: space-between;
}
.overviewCol {
  width: 45%;
}
.description {
  margin-top: 20px;
}
.description p {
  margin: 0px !important;
  font-weight: 500;
  color: var(--text-dark-gray-clr);
  font-size: 18px !important;
  margin-top: 20px !important;
}
.listItem {
}
.listItem span:first-child {
  font-weight: 600;
  color: var(--text-black-clr);
}
.listItem span:last-child {
  color: var(--text-dark-gray-clr);
  font-weight: 500;
}

.interior {
  margin-top: 20px;
}
.interiorList span {
  color: var(--text-dark-gray-clr);
  font-weight: 500;
  font-size: 18px;
}

.socialIconsWrap1 {
  display: flex;
  align-items: center;
  padding-block: 10px;
  bottom: 8px;
  left: 5px;
  /* z-index: 1; */
  width: 100%;
  background-color: #ffff;
  box-shadow: var(--card-shadow);
}
.socialIconsWrap1 svg {
  cursor: pointer;
}
.socialIcons1 {
  width: 50%;
  padding-inline: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #FFFF; */
}
.borderRight {
  border-inline-end: 1px solid #e6eaeb;
}

.clientCard {
  margin-top: 20px;
  position: sticky;
  top: 130px;
}
/* banner */
.bannerAdContainer {
  margin-top: 30px;
}

.verticalBanner {
  height: 800px;
}
.horizontalBanner {
  /* height: 250px; */
}
.similarEvents {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.propertyCarousel {
  padding-block: 30px;
}
.col1 {
  width: calc(100% - 270px);
}
.col2 {
  width: 270px;
}
@media screen and (max-width: 1220px) {
  .images {
    height: 340px;
  }
}
@media screen and (max-width: 1024px) {
  .images {
    height: 300px;
  }
}
@media screen and (max-width: 992px) {
  .col1 {
    width: 100%;
  }
  .col2 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .images {
    height: 260px;
  }
  .price p {
    color: var(--primary-clr);
    margin: 0px !important;
    font-size: 20px;

    font-weight: 600;
  }
  .desc p {
    font-size: 16px !important;
    color: #4b4b4b;
    font-weight: 600;
    margin-block: 0px !important;
  }

  .addressWrap p {
    margin: 0px !important;
    color: #656565;
    font-size: 12px;
  }

  .openHouseWrap p {
    font-weight: bold;
    font-size: 15px;
    margin: 0px !important;
    text-align: center;
  }
  .dateTime {
    font-size: 8px;
    color: var(--text-dark-gray-clr);
    /* color: #656565; */
    font-weight: 500;
  }
  .container {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .images {
    height: 240px;
  }
  .priceRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: start;
  }
  .openHouseWrap p {
    text-align: start;
  }
}

@media (max-width: 1024px) {
  .overviewCol {
    width: 100%;
  }
  .clientCard {
    position: static;
  }
  .overViewRow {
    justify-content: center;
    row-gap: 20px;
  }
}
@media (max-width: 992px) {
  .overviewCol {
    width: 100%;
  }
  .overViewRow {
    justify-content: center;
    row-gap: 20px;
  }
  .verticalBanner {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .tab {
    flex: 1;
  }
}
