.main {
  /* height: 500px; */
  width: 100%;
  padding: 0px !important;
}

.main > div {
  background-image: url(../../assets/images/waveBgSm.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.header {
  border-radius: 0px;
  height: 30px;
}
.header > div {
  top: 3px;
  right: 10px;
}
.row {
  height: 100%;
}
.col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 50px;
  height: 400px;
}
.col1 h4 {
  font-weight: 600;
  color: #029a8a;
  font-size: 35px;
}
.col1 p {
  font-size: 22px;
  color: #323434;
  font-weight: 400;
}

.iconsWrap {
  display: flex;
  gap: 30px;
}
.iconsWrap p {
  font-size: 12px;
  text-align: center;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 57px;
  background-color: #029a8a;
  border-radius: 50%;
}
.sideImage {
  /* padding-top: 100px; */
  margin-bottom: 30px;
}
.sideImage img {
  height: 100%;
  width: 100%;
}
.footer {
  width: 100%;
  height: 30px;
  background-color: #029a8a;
}
