.tabs {
    display: flex;
    align-items: center;
    height: 46px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    width: auto;
    padding-left: 0px;
    background-color: var(--white-clr);
    box-shadow: var(--card-shadow);
    margin-bottom: 0px;
    user-select: none;
}

.tabs li {
    font-size: 14px ;
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 18px;
    position: relative;
    cursor: pointer;
    scroll-snap-align: start;
    flex-shrink: 0;
    transform-origin: center center; 
    column-gap: 10px; 
    text-transform: capitalize;
    justify-content: center;

}

.tabs li.selected {
    /* background-color: var(--primary-clr); */
    color: var(--white-clr);
}
.tabs li svg{
    flex-shrink: 0;
}
.tabs li span{
    color: var(--text-dark-gray-clr);
    font-weight: 500;
}
.tabs li.selected span{
    color: var(--white-clr);
}
.tabs li:not(.selected) svg{
    color: var(--primary-clr);
}
.hideBorder{

}

.tabs li:not(.selected):not(:last-child):not(.hideBorder)::before {
    content: '';
    position: absolute;
    inset-inline-end: 0;
    top: 15%;
    bottom: 15%;
    transform: translate(-50%, -0%);
    width: 1px;
    height: 70%;
    background-color: var(--text-light-gray-clr);
}

.tabs::-webkit-scrollbar {
    height: 4px;
}
.tabs::-webkit-scrollbar-thumb{
    background-color: rgb(163, 163, 163);
}
.tabs::-webkit-scrollbar-track{
    background-color: lightgray;

}
