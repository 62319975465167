.body{

}
.gap {
  row-gap: 20px;
}
.iconDiv{
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
}
.iconDiv h6{
  color: var(--primary-clr);
}
.gap p{
  text-align: center;
}