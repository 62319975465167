.tabs {
  padding-left: 0px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  background-color: #00000073;
  width: max-content;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 41px;
  margin-bottom: 0px;
}

.tabs li {
  color: var(--white-clr);
  width: 100px;
  text-align: center;
}

.tabs li:nth-child(2) {
  border-left: 1px solid var(--text-light-gray-clr);
}

.tabs li span {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 4px;
  text-transform: capitalize;
}

.tabs li span[data-selected='true'] {
  color: var(--primary-clr);
  border-bottom: 2px solid var(--primary-clr);
}

.dropdownBox {
  height: 51px;
  background-color: var(--white-clr);
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  column-gap: 10px;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  /* box-shadow: 0.26px 0.97px 3px #00010117; */

  border: 1px solid var(--input-border-clr);
}

.dropdownBox span {
  color: var(--text-black-clr);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
}

.dropdownBox .icon {
  color: var(--secondary-clr);
  flex-shrink: 0;
}

.dropdownBox > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* otherOptions */
.otherOptions {
  background-color: #00000073;
  padding: 13px 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.otherOptionsRow {
  padding: 20px 25px;
}
.overlayBox {
  background-color: var(--white-clr);
  padding: 16px;
  box-shadow: 6px 5px 5px 2px #0000002e;

  width: 100%;
  min-width: 250px;
}

.p0 {
  padding: 0px;
}

.spBtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnMaxContent {
  width: calc(50% - 10px) !important;
  padding: 14px 5px !important;
  border: 1px solid var(--primary-clr) !important;
  font-size: 14px;
}
.clearBtn, .clearBtn:hover{
  width: calc(50% - 10px);
  padding: 14px 5px !important;
  background-color: var(--white-clr) !important;
  color: var(--primary-clr) !important;
  border: 1px solid var(--primary-clr) !important;
  font-size: 14px;
}
.searchBtn{
  width: max-content !important;
  height: 51px;
}

.headWithIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headWithIcon svg {
  color: var(--secondary-clr);
  font-size: 20px;
}

.headWithIcon h6 {
  margin-bottom: 0px;
}

.row {
  gap: 8px;
}
.minWidth350{
  min-width: 350px;
}
.mt20{
  margin-top: 20px;
}
/* Property Type */
.propertyTypeTabs {
  display: flex;
  align-items: center;
  padding-left: 0px;
  border-bottom: 1px solid var(--text-light-gray-clr);
  margin-bottom: 0px;
  user-select: none;
}

.propertyTypeTabs li {
  width: 50%;
  position: relative;
}

.propertyTypeTabs li:nth-child(2) {
  border-left: 1px solid var(--text-light-gray-clr);
}

.propertyTypeTabs li span {
  color: var(--text-black-clr);
  font-weight: 500;
  cursor: pointer;
  padding-block: 7px;
  width: max-content;
  display: block;
  margin: 0 auto;
}

.propertyTypeTabs li span[data-selected='true'] {
  color: var(--primary-clr);
  font-weight: 600;
  position: relative;
}

.propertyTypeTabs li span[data-selected='true']::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-clr);
}

.propertyTypeOptions {
  padding-left: 0px;
  margin-bottom: 0px;
}

.propertyTypeOptions li {
  border-bottom: 1px solid var(--text-light-gray-clr);
  padding: 5px 10px;
  font-weight: 500;
  color: var(--text-black-clr);
}

.propertyTypeOptions li[data-selected='true'] {
  background-color: var(--primary-clr);
  color: var(--white-clr);
}

/* Property Type */

/* Price Type */
.listOptionsWithBrBtm {
  padding-left: 0px;
  margin-bottom: 0px;
  max-height: 400px;
  overflow-y: auto;
}

.listOptionsWithBrBtm li {
  border-bottom: 1px solid var(--text-light-gray-clr);
  padding: 5px 10px;
  font-weight: 500;
  color: var(--text-black-clr);
  text-transform: capitalize;
  cursor: pointer;
}

.listOptionsWithBrBtm li[data-selected='true'] {
  background-color: var(--primary-clr);
  color: var(--white-clr);
}

.propertyType{
  min-width: 170px;
}
/* Price Type */

.width170 {
  min-width: 170px !important;
  flex-grow: 0 !important;
  width: auto;
}
.width150 {
  min-width: 150px !important;
  flex-grow: 0 !important;
  width: auto;
}
.width200 {
  min-width: 200px !important;
  flex-grow: 0 !important;
  width: auto;
}

.searchBtn {
  height: 51px;
  width: 100%;
}

.searchInput input {
  height: 51px;
}

.searchInput > div {
  border-radius: 3px;
}

.moreFiltersPopover {
  top: unset;
}

.moreFiltersPopover .overlayBox {
}

/* New Css */
.secondLineOptions {
  display: flex;
  align-items: center;
  gap: 12px;
}
.secondLineOptions > div {
  flex: 1;
}
.searchBtnWidth {
  min-width: 54px;
  flex-grow: 0 !important;
  width: auto;
}
.searchBtnWidth button {
  min-width: inherit;
}
@media screen and (max-width:1570px) {
  .minWidth350{
    width: 100%;
  }
  
}
@media screen and (max-width: 1220px) {
  .width170 {
    min-width: 140px !important;
  }
  .width190 {
    min-width: 180px !important;
  }
}
@media screen and (max-width: 1024px) {
  .width170 {
    min-width: 140px !important;
  }
}
@media screen and (max-width: 992px) {
  .searchBtnWidth {
    padding: 0px !important;
    font-size: 15px !important;
    height: 51px !important;
  }
  .dropdownBox span {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .width170 {
    min-width: 110px !important;
  }
}

@media screen and (max-width: 600px) {
  .width150{
    min-width:54px !important;
  }
  .width150 .dropdownBox{
    justify-content: center;
  }
  .width170 {
    min-width: 110px !important;
  }
  .searchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px !important;
    width: 54px !important;
    font-size: 20px;
  }
  .searchBtn span svg {
    float: unset;
  }
  .width170 {
    min-width: 54px !important;
  }
  .width170 .dropdownBox {
    justify-content: center;
  }
  .width170 .dropdownBox svg {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .searchBtn {
    width: 48px !important;
  }
  .width170 {
    min-width: 48px !important;
  }
  .row {
    gap: 6px;
  }
}
@media screen and (max-width: 425px) {
  .dropdownBox span {
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
  .width170 {
    min-width: 45px !important;
  }

  .searchBtn {
    font-size: 18px;
    width: 45px !important;
  }
}
