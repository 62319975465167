.labelValue {
  display: flex;
  column-gap: 8px;
}

.labelValue .label {
  color: var(--text-black-clr);
  font-weight: 600;
}
.labelValue .value {
  font-weight: 400;
  color: var(--dashboard-text-gray-clr);
  text-transform: capitalize;
}

.row {
  row-gap: 20px;
}
.description{
    flex-direction: column;
}

.row h5{
    margin-top: 20px;
    margin-bottom: 10px;
}

.link{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: var(--text-black-clr);
}