.page {
  
}
/* Hero Section */
.heroSection {
  min-height: calc(100vh - 243px);
  padding-bottom: 80px;
  padding-top: 100px;
  position: relative;
}
.heroSection::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url(../../assets/images/signup-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.heroSection::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0,0.8);
}
.heroRow {
  z-index: 2;
  position: sticky;
  row-gap: 20px;

}
.form {
  background: var(--page-bg-color);
  box-shadow: 0px 5px 30px #0000004f;
  padding: 20px;
  border-radius: 10px;
}
.form h4,
.form h6 {
  text-align: center;
  color: var(--primary-clr);
}

.form h6 {
  font-size: 22px;
}
.formRow {
  row-gap: 20px;
}
.form .btns{
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.form .btns button{
  padding: 17.5px 20px;
}
.googleBtn{
  background: #4285F4 !important;
  padding: 15.5px 20px !important;
}
.googleBtn:hover{
  background: #5075c5 !important;

}
.googleBtn span{
  width: 100%;
}
.alreadyRegistered{
  font-weight: 600;

}
.alreadyRegistered a{
  color: var(--secondary-clr);
  text-decoration: none;
  font-weight: 500;
}
.heroContent {
}
.heroContent p{
  color: var(--white-clr);
  margin-bottom: 0px;
}
.heroContent .logoDiv {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}
.heroContent .logoDiv img {
  width: 100%;
  height: 100%;
}
.heroContent h3 {
  color: var(--secondary-clr);
}
/* End of Hero Section */

/* powerfullSection */

 .optionsRow{
    row-gap: 20px;
    margin-top: 40px;
}
 .iconDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 140px;
}
 .iconDiv .imgDiv {
  width: auto;
  height: 50px;
  max-width: 90px;
  flex-shrink: 0;
}
 .iconDiv .imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
 .iconDiv p {
  text-align: center;
  color: var(--white-clr);
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 30px;
}

/* powerfullSection */

/* Keep in touch section */
@media screen and (max-width:1200px) {
     .form{
      max-width: 80%;
      margin: 0 auto;
     }
   
}
@media screen and (max-width:575px) {
     .form{
      max-width: 90%;
     }
    
}